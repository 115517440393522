import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingProgress } from '../components'
import { useEditCompactMode, useGetUser } from '../server/userControllers'

const KEYCLOAK_CONTEXT = React.createContext()

export const useKeycloak = () => React.useContext(KEYCLOAK_CONTEXT)

export const KeycloakProvider = ({ keycloak, children }) => {
  const { i18n } = useTranslation()
  const { me, loading } = useGetUser(keycloak.token)
  const [userInfos, setUserInfos] = React.useState()
  const [compact, setCompact] = React.useState(null)

  const { editCompactMode } = useEditCompactMode(keycloak.token)

  React.useEffect(() => {
    async function fetchUserInfos() {
      const userInfos = await keycloak.loadUserInfo()
      const locale = getLocale(userInfos)
      i18n.changeLanguage(locale)
      setUserInfos({ ...userInfos, locale })
    }
    fetchUserInfos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak, i18n])

  const toggleCompactMode = React.useCallback(() => {
    setCompact(previous => {
      const newValue = !(previous ?? me?.compact)
      editCompactMode(newValue)
      return newValue
    })
  }, [editCompactMode, me])

  const value = React.useMemo(
    () => ({
      user: userInfos,
      logout: keycloak.logout,
      token: keycloak.token,
      toggleCompactMode,
      compact: compact ?? me?.compact,
    }),
    [userInfos, keycloak, toggleCompactMode, compact, me],
  )

  return loading ? (
    <LoadingProgress />
  ) : (
    <KEYCLOAK_CONTEXT.Provider value={value} children={children} />
  )
}

export const getLocale = infos => {
  if (infos.locale)
    return Array.isArray(infos.locale) ? infos.locale[0] : infos.locale
  return Array.isArray(infos.attributes.locale)
    ? infos.attributes.locale[0]
    : infos.attributes.locale
}
