import React from 'react'
import { useWave } from '../contexts/wave'
import { useEditSelecton } from '../server/selectionsControllers'

export function useSelection(selection) {
  const [titles, setTitles] = React.useState(selection?.data?.titles || [])
  const { editSelection } = useEditSelecton(selection?.id)
  const { refetchSelections, loadingSelections } = useWave()

  React.useEffect(() => {
    !loadingSelections && setTitles(selection?.data?.titles)
  }, [setTitles, selection, loadingSelections])

  const updateTitles = titles => {
    editSelection({
      ...selection,
      data: { ...selection.data, titles },
    }).then(() => refetchSelections())
  }

  const rename = name => {
    editSelection({
      ...selection,
      name,
    }).then(() => refetchSelections())
  }

  const toggleTitle = titleId => {
    setTitles((prev = []) => {
      const titles = prev.includes(titleId)
        ? prev.filter(id => id !== titleId)
        : [...prev, titleId]

      updateTitles(titles)
      return titles
    })
  }

  const addTitles = (titleIds = []) => {
    setTitles(prev => {
      const titles = [...prev, ...titleIds.filter(id => !prev.includes(id))]

      updateTitles(titles)
      return titles
    })
  }

  const removeTitles = (titleIds = []) => {
    setTitles(prev => {
      const titles = prev.filter(id => !titleIds.includes(id))

      updateTitles(titles)
      return titles
    })
  }

  return { titles, toggleTitle, addTitles, removeTitles, rename }
}
