import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

// import './_OutlinedInput.scss'

const SIZES = ['medium', 'small']
const CONTENT_TYPES = ['text', 'action']

function OutlinedInput({
  children,
  label,
  helper,
  showHelperOnHover,
  size,
  contentType,
  multiline,
  error,
  filled,
  focused,
  disabled,
  adornments,
  multilineAdornments,
  id,

  // Forwarded props.
  className,
  refProp,
  ...rest
}) {
  const inputClassName = classNames(
    className,
    'OutlinedInput',
    `OutlinedInput--size-${size}`,
    `OutlinedInput--content-type-${contentType}`,
    {
      'OutlinedInput--focus': focused,
      'OutlinedInput--filled': filled,
      'OutlinedInput--error': error != null,
      'OutlinedInput--disabled': disabled,
      'OutlinedInput--multiline-adornments': multilineAdornments,
    },
  )

  const labelClassName = classNames('OutlinedInput__label', {
    [`OutlinedInput__label--multiline-${size}`]: multiline,
    'OutlinedInput__label--focus': focused,
    'OutlinedInput__label--filled': filled,
    'OutlinedInput__label--error': error != null,
    'OutlinedInput__label--disabled': disabled,
  })

  // The dummy label is used to "mesure" the space needed by the label when
  // it's on top.
  const dummyLabelClassName = classNames('OutlinedInput__dummy-label', {
    'OutlinedInput__dummy-label--focus': focused,
    'OutlinedInput__dummy-label--filled': filled,
    'OutlinedInput__dummy-label--disabled': disabled,
  })

  const helperClassName = classNames('OutlinedInput__helper', {
    'OutlinedInput__helper--focus': focused,
    'OutlinedInput__helper--disabled': disabled,
    'OutlinedInput__helper--error': error != null,
    'OutlinedInput__helper--show-on-hover': showHelperOnHover,
  })

  const contentClassName = classNames('OutlinedInput__content', {
    'OutlinedInput__content--with-adornments': adornments.length > 0,
    [`OutlinedInput__content--multiline-adornments-${size}`]: multilineAdornments,
  })

  const adornmentsClassName = classNames('OutlinedInput__adornments', {
    [`OutlinedInput__adornments--multiline-${size}`]: multiline,
  })

  return (
    <span {...rest} ref={refProp} className={inputClassName}>
      <span aria-hidden="true" className={dummyLabelClassName}>
        {label}
      </span>

      <label className={labelClassName} htmlFor={id}>
        {label}
      </label>

      <span className={contentClassName}>{children}</span>

      {adornments.length > 0 &&
        // We use `React.createElement` instead of JSX to avoid having to apply
        // keys on each child.
        React.createElement(
          'span',
          { className: adornmentsClassName, onClick: e => e.stopPropagation() },
          ...adornments,
        )}

      <span className={helperClassName}>{error == null ? helper : error}</span>
    </span>
  )
}

OutlinedInput.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  helper: PropTypes.string,
  showHelperOnHover: PropTypes.bool,
  size: PropTypes.oneOf(SIZES).isRequired,
  contentType: PropTypes.oneOf(CONTENT_TYPES).isRequired,
  multiline: PropTypes.bool,
  error: PropTypes.string,
  filled: PropTypes.bool.isRequired,
  focused: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  adornments: PropTypes.arrayOf(PropTypes.element).isRequired,
  multilineAdornments: PropTypes.bool,
  id: PropTypes.string.isRequired,
}

OutlinedInput.defaultProps = {
  showHelperOnHover: false,
  multiline: false,
  multilineAdornments: false,
}

const OutlinedInputRef = React.forwardRef((props, ref) => (
  <OutlinedInput {...props} refProp={ref} />
))

OutlinedInputRef.SIZES = SIZES
OutlinedInputRef.defaultProps = OutlinedInput.defaultProps

export { OutlinedInputRef as OutlinedInput }
