import { Router } from '@reach/router'
import React from 'react'
import { LoadingProgress, SnackbarContainer } from '../components'
import { ManageScroll } from '../core/ManageScroll'
import { Topbar } from '../layout/Topbar'

const Home = React.lazy(() => import('../pages/Home/Home'))
const Wave = React.lazy(() => import('../pages/Wave/Wave'))

const Overview = React.lazy(() => import('../pages/Overview/Overview'))
const Echantillon = React.lazy(() => import('../pages/Echantillon/Echantillon'))
const Ranking = React.lazy(() => import('../pages/Ranking/Ranking'))
const Trends = React.lazy(() => import('../pages/Trends/Trends'))
const Scores = React.lazy(() => import('../pages/Scores/Scores'))
const ScoresVersus = React.lazy(() =>
  import('../pages/ScoresVersus/ScoresVersus'),
)
const Segments = React.lazy(() => import('../pages/Segments/Segments'))
const SegmentsVersus = React.lazy(() =>
  import('../pages/SegmentsVersus/SegmentsVersus'),
)
const Compare = React.lazy(() => import('../pages/Compare/Compare'))
const Expert = React.lazy(() => import('../pages/Expert/Expert'))
const Selections = React.lazy(() => import('../pages/Selections/Selections'))
const Title = React.lazy(() => import('../pages/Title/Title'))

export function App() {
  return (
    <main className="App">
      <Topbar />
      <React.Suspense fallback={<LoadingProgress />}>
        <Router>
          <Home path="/" />

          <Wave path="/wave/:waveId">
            <Overview path="overview" />
            <Echantillon path="echantillon" />
            <Ranking path="ranking" />
            <Trends path="trends" />
            <Scores path="details/scores" />
            <ScoresVersus path="details/compare-scores" />
            <Segments path="details/segments" />
            <SegmentsVersus path="details/compare-segments" />
            <Compare path="compare" />
            <Expert path="expert" />
            <Expert path="expert/:vueId" />
            <Selections path="selections/:selectionId" />
            <Title path="title/:titleId" />
          </Wave>
        </Router>

        <ManageScroll />
      </React.Suspense>
      <SnackbarContainer />
    </main>
  )
}
