import classNames from 'classnames'
import PropTypes from 'prop-types'

const COLORS = ['pink', 'orange']
export function Progress({
  className,
  value = 0,
  style = {},
  color,
  ...props
}) {
  return (
    <span
      style={{ width: `${value}%`, ...style }}
      className={classNames('Progress', `Progress--${color}`, className)}
      {...props}
    />
  )
}

Progress.defaultProps = {
  color: 'pink',
}

Progress.propTypes = {
  value: PropTypes.number,
  color: PropTypes.oneOf(COLORS),
}

Progress.COLORS = COLORS
