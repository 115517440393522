import PropTypes from 'prop-types'
import React from 'react'
import { ListItem } from './ListItem'

export function SelectOption({
  item: option,
  data: {
    additionalOptionComponent: AdditionalOptionComponent,
    additionalOptionData,
    optionComponent: OptionComponent,
    optionData,
    getOptionLabel,
  },

  // We don't want to apply `selected` and `disabled` on the
  // `AdditionalOptionComponent`
  selected,
  disabled,

  ...rest
}) {
  if (
    AdditionalOptionComponent != null &&
    option === AdditionalOptionComponent
  ) {
    return (
      <AdditionalOptionComponent
        {...rest}
        data={additionalOptionData}
        className="SelectOption__additionalOption"
      />
    )
  }

  if (OptionComponent == null) {
    return (
      <ListItem
        {...rest}
        highlightOnHover
        selected={selected}
        disabled={disabled}
      >
        <ListItem.Text>{getOptionLabel(option)}</ListItem.Text>
      </ListItem>
    )
  }

  return (
    <OptionComponent
      {...rest}
      item={option}
      data={optionData}
      selected={selected}
      disabled={disabled}
    />
  )
}

SelectOption.propTypes = {
  item: PropTypes.any,
  data: PropTypes.shape({
    additionalOptionComponent: PropTypes.elementType,
    additionalOptionData: PropTypes.any,
    optionComponent: PropTypes.elementType,
    optionData: PropTypes.any,
    getOptionLabel: PropTypes.func.isRequired,
  }).isRequired,
}
