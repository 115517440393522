import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { TableCell } from '../TableCell'
import { TableRow } from '../TableRow/TableRow'

function TableRowTitle({ children, refProp, className, ...rest }) {
  return (
    <TableRow
      {...rest}
      ref={refProp}
      className={classNames(className, 'TableRowTitle')}
    >
      <TableCell className="TableRowTitle__cell">children</TableCell>
    </TableRow>
  )
}

TableRowTitle.propTypes = {
  hover: PropTypes.bool,
  disabled: PropTypes.bool,
}

TableRowTitle.defaultProps = {
  disabled: false,
}

const TableRowTitleWithRef = React.forwardRef((props, ref) => (
  <TableRowTitle {...props} refProp={ref} />
))

export { TableRowTitleWithRef as TableRowTitle }
