import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    ns: ['translation'],
    defaultNS: 'translation',

    debug: process.env.NODE_ENV !== 'production',

    react: {
      // Wait for the translations to be loaded before rendering the content.
      // This avoid having the default language displayed before changing to the current one.
      wait: true,
    },
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
