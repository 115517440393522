import React from 'react'

import WaveSurfer from 'wavesurfer.js'

import colors from '../../sass/base/_colors.module.scss'

export function Waveform({ play, src, onFinish }) {
  const waveformRef = React.useRef()
  const wavesurfer = React.useRef()

  React.useEffect(() => {
    wavesurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: 'rgb(127, 127, 127)',
      cursorColor: 'rgba(200, 54, 54, 0)',
      barWidth: 1,
      barGap: 5,
      height: 70,
      pixelRatio: 1,
      progressColor: colors.colorblue,
      backend: 'MediaElement',
    })
    wavesurfer.current.load(src)
    wavesurfer.current.on('finish', () => {
      onFinish()
    })
    return () => wavesurfer.current.destroy()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (wavesurfer.current) {
      if (play) {
        return wavesurfer.current.play()
      }
      wavesurfer.current.pause()
    }
  }, [play])

  return (
    <div className="waveform">
      <div ref={waveformRef} />
    </div>
  )
}
