import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { composeRefs, watchResize } from 'react-behave'
// import './_AutoSizeInput.scss'

// We consider that the cursor of the input is 1px thick on all browsers.
const CURSOR_THICKNESS = 1

const WATCH_RESIZE_OPTIONS = {
  // Correspond to 1 frame.
  resizeInterval: 16,
}

function AutoSizeInput({
  value,

  // Forwarded props.
  className,
  style,
  refProp,
  ...rest
}) {
  const inputElement = React.useRef(null)
  const rulerElement = React.useRef(null)

  React.useLayoutEffect(() => {
    // The resize callback don't get called with the initial size so we force
    // the width to its minimum value.
    // We don't use a state to avoid endless renders.
    inputElement.current.style.width = `${CURSOR_THICKNESS}px`

    return watchResize(
      rulerElement.current,
      ({ width }) => {
        const size = Math.ceil(width)
        inputElement.current.style.width = `${size + CURSOR_THICKNESS}px`
      },
      WATCH_RESIZE_OPTIONS,
    )
  }, [])

  // Space characters in HTML are not preserved so we need to replace them by a
  // character of same width that is preserved: the non-breaking-space
  // character (`&nbsp;`).
  // We use the unicode format of the character because React escape `&nbsp;`.
  // We only support spaces for now.
  const rulerValue = value.replace(/ /g, '\u00a0')

  return (
    <span className={classNames('AutoSizeInput', className)} style={style}>
      <span ref={rulerElement} className="AutoSizeInput__ruler">
        {rulerValue}
      </span>

      <input
        {...rest}
        value={value}
        ref={composeRefs([inputElement, refProp])}
        className="AutoSizeInput__nativeInput"
      />
    </span>
  )
}

AutoSizeInput.propTypes = {
  value: PropTypes.string.isRequired,
}

const AutoSizeInputRef = React.forwardRef((props, ref) => (
  <AutoSizeInput {...props} refProp={ref} />
))

export { AutoSizeInputRef as AutoSizeInput }
