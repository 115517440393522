import { useOnClickOutside, useInput } from '@ttrmz/react-utils'
import { Link } from '@reach/router'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Dropdown, Input, Title, Table } from '../'
import { useLayout } from '../../contexts/layout'
import { useSearchStationTitles } from '../../server/stationsControllers'
import { useFormatDate } from '../../core/formatDate'
import fallbackCover from '../TinyPlayer/fallback.svg'

const LIMIT = 30

export function SearchTitles({ isSearchFocused, onSearchFocused }) {
  const { t } = useTranslation()
  const { formatDate } = useFormatDate()
  const { wave } = useLayout()
  const [titlesDropdownOpened, toggleTitlesDropdown] = React.useState(false)
  const { value: search, onChange: setSearch } = useInput('')
  const { titles } = useSearchStationTitles(wave?.station.id, {
    search,
    limit: LIMIT,
  })
  const dropdownTitles = React.useRef()
  useOnClickOutside(dropdownTitles, () => {
    toggleTitlesDropdown(false)
    onSearchFocused(false)
  })

  React.useEffect(() => {
    if (search && isSearchFocused) {
      toggleTitlesDropdown(true)
    }
  }, [search, isSearchFocused])

  const assetUrl =
    process.env.NODE_ENV === 'development'
      ? 'https://staging.hitindex.hyperworld.fr/hooks/'
      : `${process.env.REACT_APP_API_DOMAIN}hooks/`

  return (
    <div className="SearchTitles" ref={dropdownTitles}>
      <Input
        label={t('searchWave')}
        className={classNames('SearchTitles__Input', {
          'SearchTitles__Input--focused': isSearchFocused,
        })}
        onFocus={() => onSearchFocused(true)}
        value={search}
        icon={'search'}
        onChange={setSearch}
      />
      {titlesDropdownOpened && (
        <Dropdown className="SearchTitles__Dropdown">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Cell
                  weight="12"
                  align="left"
                  className="Overview__Table__head__label"
                >
                  {t('artist')} &nbsp; &nbsp;{t('title')}
                </Table.Cell>
                <Table.Cell
                  weight="6"
                  align="center"
                  className="Overview__Table__head__label"
                >
                  {t('lastAppear')}
                </Table.Cell>
                <Table.Cell
                  weight="1"
                  align="center"
                  className="Overview__Table__head__label"
                >
                  {t('tests')}
                </Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {titles.length > 0 ? (
                titles.map(title => {
                  const coverUrl = `${assetUrl}${wave.station.name}_hit_${title.ref}.jpg`
                  const isSameWave = wave?.id === title.lastWave.id
                  return (
                    <Link to={`/wave/${title.lastWave.id}/title/${title.id}`}>
                      <Table.Row className="DropdownItem" onClick>
                        <Table.Cell
                          weight="12"
                          align="left"
                          className="SearchTitles__Dropdown_Item__Label"
                        >
                          <div
                            style={{
                              backgroundImage: `url(${coverUrl}), url(${fallbackCover})`,
                            }}
                            className="SearchTitles__img"
                          />
                          <Title
                            title={{ ...title, name: title.title }}
                            noActions
                          />
                        </Table.Cell>
                        <Table.Cell
                          weight="6"
                          align="center"
                          className={classNames(
                            'SearchTitles__Dropdown_Item__Label',
                            {
                              'SearchTitles__Dropdown_Item__Label-smWave': isSameWave,
                            },
                          )}
                        >{`${t('wave')} ${title.lastWave.number} - ${formatDate(
                          title.lastWave.publishedAt,
                          {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                          },
                        )}`}</Table.Cell>
                        <Table.Cell
                          weight="1"
                          align="center"
                          className="SearchTitles__Dropdown_Item__Label"
                        >
                          {title.occurence}
                        </Table.Cell>
                      </Table.Row>
                    </Link>
                  )
                })
              ) : (
                <Table.Row>
                  <Table.Cell>{t('noTitle')}</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Dropdown>
      )}
    </div>
  )
}
