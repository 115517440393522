import { useLocation } from '@reach/router'
import React from 'react'

let scrollPositions = {}
let date = Date.now()

export const ManageScroll = () => {
  const location = useLocation()

  const listener = React.useCallback(() => {
    const now = Date.now()
    if (location && location.href && now - date > 500) {
      scrollPositions[location.href] = window.scrollY
    }
  }, [location])

  React.useEffect(() => {
    if (scrollPositions[location.href]) {
      date = Date.now()
      setTimeout(
        () => window.scrollTo(0, scrollPositions[location.href] || 0),
        500,
      )
    }
    window.addEventListener('scroll', listener)
    return () => window.removeEventListener('scroll', listener)
  }, [location?.href, listener])

  return null
}
