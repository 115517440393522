export const INVERTED_REFS = [
  'burn_w',
  'rejet_w',
  'pas_w',
  'score_all_neg_w',
  'unknown_w',
]

export const PERCENT_TYPES = { absolute: 'absolute', default: 'percent' }

export const SCORES_TYPES = {
  score_pot_w: PERCENT_TYPES.absolute,
  score_pot_fam_w: PERCENT_TYPES.absolute,
  score_w: PERCENT_TYPES.absolute,
  score_no_fam_w: PERCENT_TYPES.absolute,
  score_fam_w: PERCENT_TYPES.absolute,
  score_all_w: PERCENT_TYPES.absolute,
  redress: PERCENT_TYPES.absolute,
  fam_w: PERCENT_TYPES.default,
  rejet_w: PERCENT_TYPES.default,
  reco_w: PERCENT_TYPES.default,
  pas_w: PERCENT_TYPES.default,
  passion_w: PERCENT_TYPES.default,
  noburn_w: PERCENT_TYPES.default,
  unknown_w: PERCENT_TYPES.default,
  burn_w: PERCENT_TYPES.default,
  bcp_w: PERCENT_TYPES.default,
  score_all_neg_w: PERCENT_TYPES.default,
  score_all_pos_w: PERCENT_TYPES.default,
}

export const NUMERIC_CELL_WIDTH = 120
export const NUMERIC_CELL_WEIGHT = 1

export const MAPPING_COLORS = {
  score_pot_w: '#8C317F',
  score_all_w: '#8C317F',
  fam_w: '#D1167A',
  passion_w: '#EF1530',
  rejet_w: '#FA5636',
  burn_w: '#FF9040',
  reco_w: '#FFBF38',
  score_fam_w: '#EAC33D',
  score_nofam_w: '#455FC5',
  score_w: '#6BC086',
  score_pot_fam_w: '#9FCE50',
  pas_w: '#3193AB',
  bcp_w: '#05335B',
  score_all_pos_w: '#8C317F',
  score_all_neg_w: '#D1167A',
  unknown_w: '#EAC33D',
}

export const SELECTIONS_TYPES = {
  selection: 'selection',
  expert: 'expert',
}
