import { useInput } from '@ttrmz/react-utils'
import React from 'react'
import { useWave } from '../../contexts/wave'
import { useEditSelecton } from '../../server/selectionsControllers'
import { Button } from '../Button'
import { Card } from '../Card'
import { Input } from '../Input'
import { Modal } from '../Modal'
import { stopEvent } from '@ttrmz/react-utils'
import { showSnackbar } from '../../core/showSnackbar'
import { useTranslation } from 'react-i18next'

export function SelectionRenameModal({
  children,
  selection,
  onClose,
  ...rest
}) {
  const { t } = useTranslation()
  const { value: name, onChange, setValue: setName } = useInput(
    selection?.name || '',
  )
  const { editSelection } = useEditSelecton(selection?.id)
  const { refetchSelections } = useWave()

  React.useEffect(() => {
    setName(selection?.name)
  }, [selection?.name, setName])

  const handleConfirmRename = event => {
    stopEvent(event)

    editSelection({
      ...selection,
      name,
    }).then(() => {
      onClose()
      refetchSelections()
      showSnackbar(t('selectionDeleted'))
    })
  }

  return (
    <Modal onClose={onClose} open={!!selection} {...rest}>
      <Card component="form" onSubmit={handleConfirmRename}>
        <h3 className="SelectionRenameModal__title">
          {t('rename')} {selection?.name}
        </h3>

        <Input
          icon="rename"
          className="SelectionRenameModal__input"
          value={name}
          onChange={onChange}
          label={t('newPlaylist')}
        />

        <div className="SelectionRenameModal__actions">
          <Button onClick={() => onClose()} color="grey">
            {t('cancel')}
          </Button>
          <Button type="submit">{t('confirm')}</Button>
        </div>
      </Card>
    </Modal>
  )
}
