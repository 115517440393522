import classNames from 'classnames'
import PropTypes from 'prop-types'

export function Placeholder({ component: Component, className, ...rest }) {
  return (
    <Component className={classNames(className, 'Placeholder')} {...rest} />
  )
}

Placeholder.propTypes = {
  component: PropTypes.elementType,
}

Placeholder.defaultProps = {
  component: 'span',
}
