import { useKeycloak } from '../contexts/keycloak'

export function useFormatDate() {
  const { user } = useKeycloak()

  function formatDate(value, options) {
    return new Date(value).toLocaleDateString(user?.locale, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      ...options,
    })
  }

  return { formatDate }
}
