import { useKeycloak } from '../contexts/keycloak'
import { getBearerTokenHeaders } from '../core/getBearerTokenHeaders'
import { useLazyFetch } from './useLazyFetch'

export function useAuthenticatedLazyFetch(path) {
  const { token } = useKeycloak()

  const { lazyFetch: fetchData, ...rest } = useLazyFetch(
    `${process.env.REACT_APP_API_DOMAIN}api/hit${path}`,
  )

  const lazyFetch = (options = {}, queryString) =>
    fetchData({
      ...options,
      headers: {
        ...getBearerTokenHeaders(token),
        ...options?.headers,
      },
      queryString,
    })

  return { lazyFetch, ...rest }
}
