import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { composeHandlers, usePopper, watchResize } from 'react-behave'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useComposedRefs } from '../../hooks/useComposedRefs'
import { useID } from '../../hooks/useID'

// The spacing between a tooltip and a side.
const SIDE_SPACING = 8

// The offset between a tooltip and its reference.
const TOOLTIP_OFFSET = 12

const STATES = {
  // Nothing goin' on.
  IDLE: 'idle',

  // We're considering showing the tooltip, but we're gonna wait a sec.
  FOCUSED: 'focused',

  // IT'S ON.
  VISIBLE: 'visible',

  // Focus has left, but we want to keep it visible for a sec.
  LEAVING_VISIBLE: 'leavingVisible',

  // Just hid the tooltip, but we're waiting in case another need to show up.
  HOT: 'hot',

  // The user clicked the tool, so we want to hide the thing, we can't just use
  // IDLE because we need to ignore mousemove, etc.
  DISMISSED: 'dismissed',
}

// Only one tooltip can be visible at a time, so we use a global state chart to
// describe the various states and transitions between states that are
// possible.
// With all the timeouts involved with tooltips it's important to "make
// impossible states impossible" with a state machine.
const STATE_CHART = {
  initial: STATES.IDLE,
  states: {
    [STATES.IDLE]: {
      enter: clearContextId,
      on: {
        mouseenter: STATES.FOCUSED,
      },
    },
    [STATES.FOCUSED]: {
      enter: startRestTimer,
      leave: clearRestTimer,
      on: {
        mousemove: STATES.FOCUSED,
        mouseleave: STATES.IDLE,
        mousedown: STATES.DISMISSED,
        rest: STATES.VISIBLE,
      },
    },
    [STATES.VISIBLE]: {
      on: {
        mouseenter: STATES.FOCUSED,
        mouseleave: STATES.LEAVING_VISIBLE,
        mousedown: STATES.DISMISSED,
        selectWithKeyboard: STATES.DISMISSED,
      },
    },
    [STATES.LEAVING_VISIBLE]: {
      enter: startLeavingVisibleTimer,
      leave: () => {
        clearLeavingVisibleTimer()
        clearContextId()
      },
      on: {
        mouseenter: STATES.VISIBLE,
        timecomplete: STATES.HOT,
      },
    },
    [STATES.HOT]: {
      enter: startHotTimer,
      leave: clearHotTimer,
      on: {
        mouseenter: STATES.VISIBLE,
        timecomplete: STATES.IDLE,
      },
    },
    [STATES.DISMISSED]: {
      leave: clearContextId,
      on: {
        mouseleave: STATES.IDLE,
      },
    },
  },
}

// Chart context allows us to persist some data around, in Tooltip all we use
// is the id of the current tooltip being interacted with.
let context = { id: null }
let state = STATE_CHART.initial

// Finds the next state from the current state + action.
// If the chart doesn't describe that transition, it will throw.
//
// It also manages lifecycles of the machine, (enter/leave hooks on the state
// chart).
function transition(action, newContext) {
  const stateDef = STATE_CHART.states[state]
  const nextState = stateDef.on[action]

  if (!nextState) {
    throw new Error(
      `Unknown state for action "${action}" from state "${state}"`,
    )
  }

  if (stateDef.leave != null) {
    stateDef.leave()
  }

  if (newContext != null) {
    context = newContext
  }

  const nextDef = STATE_CHART.states[nextState]

  if (nextDef.enter != null) {
    nextDef.enter()
  }

  state = nextState
  notify()
}

// We could require apps to render a <TooltipProvider> around the app and use
// React context to notify Tooltips of changes to our state machine, instead
// we manage subscriptions ourselves and simplify the Tooltip API.
//
// Maybe if default context could take a hook (instead of just a static value)
// that was rendered at the root for us, that'd be cool! But it doesn't.
const subscriptions = []

function subscribe(fn) {
  subscriptions.push(fn)
  return () => {
    subscriptions.splice(subscriptions.indexOf(fn), 1)
  }
}

function notify() {
  subscriptions.forEach(fn => fn(state, context))
}

// Manages when the user "rests" on an element.
// Keeps the interface from being flashing tooltips all the time as the user
// moves the mouse around the screen.
let restTimeout = null

function startRestTimer() {
  clearTimeout(restTimeout)
  restTimeout = setTimeout(() => transition('rest'), Tooltip.REST_DELAY)
}

function clearRestTimer() {
  clearTimeout(restTimeout)
}

// Manages the delay to hide the tooltip after rest leaves.
let leavingVisibleTimer = null

function startLeavingVisibleTimer() {
  clearTimeout(leavingVisibleTimer)
  leavingVisibleTimer = setTimeout(
    () => transition('timecomplete'),
    Tooltip.LEAVE_DELAY,
  )
}

function clearLeavingVisibleTimer() {
  clearTimeout(leavingVisibleTimer)
}

// Manages the delay to hot display the next tooltip.
let hotTimeout = null

function startHotTimer() {
  clearTimeout(hotTimeout)
  hotTimeout = setTimeout(() => transition('timecomplete'), Tooltip.HOT_DELAY)
}

function clearHotTimer() {
  clearTimeout(hotTimeout)
}

// allows us to come on back later w/o entering something else first after the
// user leaves or dismisses
function clearContextId() {
  context.id = null
}

function hasOverflow(element) {
  return (
    element?.offsetWidth < element?.scrollWidth ||
    element?.offsetHeight < element?.scrollHeight
  )
}

export function Tooltip({
  content,
  children,
  onlyIfOverflow,
  placement,
  popperModifiers,

  // Forwarded props.
  className,
  style: styleProp,
  ...rest
}) {
  const id = useID()
  const { t } = useTranslation()

  const translatedText = typeof content === 'string' ? t(content) : content

  const moutingPoint = React.useMemo(
    () =>
      typeof document === 'undefined' ? null : document.createElement('div'),
    [],
  )

  const [visible, setVisible] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)
  const referenceElement = React.useRef(null)
  const popperElement = React.useRef(null)
  const arrowElement = React.useRef(null)

  const modifiers = React.useMemo(() => {
    return {
      offset: { offset: `0px, ${TOOLTIP_OFFSET}px` },
      preventOverflow: {
        // We want to bound the tooltips in the `viewport` and not the default
        // `scrollParent`.
        // https://popper.js.org/popper-documentation.html#modifiers..preventOverflow.boundariesElement
        boundariesElement: 'viewport',
        padding: {
          top: SIDE_SPACING,
          right: SIDE_SPACING,
          bottom: SIDE_SPACING,
          left: SIDE_SPACING,
        },
      },
      ...popperModifiers,
    }
  }, [popperModifiers])

  const popper = usePopper(referenceElement, popperElement, {
    disabled: !visible,
    arrowRef: arrowElement,
    modifiers,
    placement,
  })

  React.useEffect(() => {
    if (!disabled) {
      return subscribe(() => {
        if (
          context.id === id &&
          (state === STATES.VISIBLE || state === STATES.LEAVING_VISIBLE)
        ) {
          setVisible(true)
        } else {
          setVisible(false)
        }
      })
    }
  }, [id, disabled])

  React.useEffect(() => {
    if (onlyIfOverflow) {
      return watchResize(referenceElement.current, () => {
        setDisabled(content == null || !hasOverflow(referenceElement.current))
      })
    }
  }, [onlyIfOverflow, content])

  function handleMouseEnter() {
    // eslint-disable-next-line default-case
    switch (state) {
      case STATES.IDLE:
      case STATES.VISIBLE:
      case STATES.LEAVING_VISIBLE:
      case STATES.HOT: {
        transition('mouseenter', { id })
      }
    }
  }

  function handleMouseMove() {
    // eslint-disable-next-line default-case
    switch (state) {
      case STATES.FOCUSED: {
        transition('mousemove', { id })
      }
    }
  }

  function handleMouseLeave() {
    // eslint-disable-next-line default-case
    switch (state) {
      case STATES.FOCUSED:
      case STATES.VISIBLE:
      case STATES.DISMISSED: {
        transition('mouseleave')
      }
    }
  }

  function handleMouseDown() {
    // Allow quick click from one tool to another
    if (context.id !== id) {
      return
    }

    // eslint-disable-next-line default-case
    switch (state) {
      case STATES.FOCUSED:
      case STATES.VISIBLE: {
        transition('mousedown')
      }
    }
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      // eslint-disable-next-line default-case
      switch (state) {
        case STATES.VISIBLE: {
          transition('selectWithKeyboard')
        }
      }
    }
  }

  React.useLayoutEffect(() => {
    if (visible && typeof document !== 'undefined') {
      document.body.appendChild(moutingPoint)
      return () => document.body.removeChild(moutingPoint)
    }
  }, [visible, moutingPoint])

  const child = React.Children.only(children)
  let newProps = { ref: useComposedRefs([referenceElement, child.ref]) }

  if (!disabled) {
    newProps = {
      ...newProps,
      onMouseEnter: composeHandlers([
        handleMouseEnter,
        child.props.onMouseEnter,
      ]),
      onMouseMove: composeHandlers([handleMouseMove, child.props.onMouseMove]),
      onMouseLeave: composeHandlers([
        handleMouseLeave,
        child.props.onMouseLeave,
      ]),
      onKeyDown: composeHandlers([handleKeyDown, child.props.onKeyDown]),
      onMouseDown: composeHandlers([handleMouseDown, child.props.onMouseDown]),
    }
  }

  const tooltipClass = classNames(className, 'Tooltip', {
    'Tooltip--out-of-boundaries': popper.outOfBoundaries,
  })

  return (
    <>
      {React.cloneElement(child, newProps)}

      {visible &&
        ReactDOM.createPortal(
          <div
            {...rest}
            ref={popperElement}
            style={{ ...styleProp, ...popper.style }}
            className={tooltipClass}
          >
            {translatedText}

            <div
              ref={arrowElement}
              style={popper.arrowStyle}
              data-placement={popper.placement}
              className="Tooltip__arrow"
            />
          </div>,
          moutingPoint,
        )}
    </>
  )
}

Tooltip.propTypes = {
  /**
   * Content of the tooltip.
   */
  content: PropTypes.node,

  /**
   * Reference element of the tootlip.
   */
  children: PropTypes.element.isRequired,

  /**
   * Display the tooltip only if the reference element is overflowing.
   */
  onlyIfOverflow: PropTypes.bool,

  /**
   * The pacement of the tooltip.
   *
   * See PopperJS's placements here: https://popper.js.org/popper-documentation.html#Popper.placements.
   */
  placement: PropTypes.oneOf(usePopper.PLACEMENTS),

  /**
   * Modifiers used to alter the behavior of the tooltip.
   *
   * The modifier `preventOverflow` is updated to be aware of the layout
   * components.
   * It ensures the tooltips do not overlap them.
   *
   * See PopperJS's modifiers here: https://popper.js.org/popper-documentation.html#modifiers.
   */
  popperModifiers: PropTypes.object,
}

Tooltip.defaultProps = {
  onlyIfOverflow: false,
  placement: 'top',
  popperModifiers: {},
}

Tooltip.REST_DELAY = 50
Tooltip.LEAVE_DELAY = 100
Tooltip.HOT_DELAY = 250
Tooltip.PLACEMENTS = usePopper.PLACEMENTS
