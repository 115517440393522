import classnames from 'classnames'

export function Card({
  className,
  title,
  children,
  component: Component,
  ...rest
}) {
  const classes = classnames('Card', className)
  return (
    <Component className={classes} {...rest}>
      {title && <div className="Card__header">{title}</div>}
      {children}
    </Component>
  )
}

Card.defaultProps = {
  component: 'div',
}
