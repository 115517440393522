import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
// import './_ListItemText.scss'
export default function ListItemText({
  component: Component,

  // Forwarded props.
  className,
  refProp,
  ...rest
}) {
  return (
    <Component
      {...rest}
      className={classNames('ListItemText', className)}
      ref={refProp}
    />
  )
}

ListItemText.propTypes = {
  /**
   * The component to render.
   */
  component: PropTypes.elementType,
}

ListItemText.defaultProps = {
  component: 'span',
}

const ListItemTextRef = React.forwardRef((props, ref) => (
  <ListItemText {...props} refProp={ref} />
))

ListItemTextRef.defaultProps = ListItemText.defaultProps

export { ListItemTextRef as ListItemText }
