import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Icon } from '../../Icon'
import { TableCellText } from '../TableCellText'
import { SORT_DIRECTIONS } from '../tablePropTypes'

const SORT_DIRECTIONS_ICON = {
  asc: 'sort-asc',
  desc: 'sort-desc',
  default: 'sort-default',
}

export function TableCellSortableText({
  sortDirection,
  hideIcon,
  children,
  className,
  ...rest
}) {
  const iconName = SORT_DIRECTIONS_ICON[sortDirection]
  const Component = hideIcon ? 'span' : 'button'

  return (
    <Component
      {...rest}
      className={classNames(className, 'TableCellSortableText', {
        'TableCellSortableText--noIcon': hideIcon,
      })}
    >
      <TableCellText children={children} />

      {children && !hideIcon && (
        <Icon
          name={iconName}
          className={classNames('TableCellSortableText__sortIcon')}
        />
      )}
    </Component>
  )
}

TableCellSortableText.defaultProps = {
  sortDirection: 'default',
}

TableCellSortableText.propTypes = {
  sortDirection: PropTypes.oneOf(SORT_DIRECTIONS),
}
