import React from 'react'

export function getUniqueID() {
  return (
    '_' +
    Math.random()
      .toString(36)
      .substring(2, 8) +
    new Date().getUTCMilliseconds()
  )
}

export function useID(id) {
  return React.useMemo(() => (id == null ? getUniqueID() : id), [id])
}
