import { stopEvent } from '@ttrmz/react-utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { SELECTIONS_TYPES } from '../../config/constants'
import { useWave } from '../../contexts/wave'
import { showSnackbar } from '../../core/showSnackbar'
import { useDeleteSelection } from '../../server/selectionsControllers'
import { Button } from '../Button'
import { Card } from '../Card'
import { Modal } from '../Modal'

export function SelectionDeleteModal({
  children,
  selection,
  onClose,
  ...rest
}) {
  const { t } = useTranslation()
  const { deleteSelection } = useDeleteSelection(selection?.id)
  const { refetchSelections } = useWave()

  const handleConfirmRename = event => {
    stopEvent(event)

    deleteSelection().then(() => {
      onClose()
      refetchSelections()
      showSnackbar(
        selection.type === SELECTIONS_TYPES.expert
          ? t('viewDeleted')
          : t('selectionDeleted'),
      )
    })
  }

  return (
    <Modal onClose={onClose} open={!!selection} {...rest}>
      <Card component="form" onSubmit={handleConfirmRename}>
        <h3 className="SelectionDeleteModal__title">
          {t('delete')} {selection?.name}
        </h3>

        <div className="SelectionDeleteModal__actions">
          <Button onClick={() => onClose()} color="grey">
            {t('cancel')}
          </Button>

          <Button type="submit"> {t('confirm')}</Button>
        </div>
      </Card>
    </Modal>
  )
}
