import React from 'react'
import { useOnClickOutside } from '@ttrmz/react-utils'
import { useTranslation } from 'react-i18next'
import { Dropdown, Button } from '../'

export function ExportActions({
  onExportGlobal,
  onExportActive,
  activeLabel,
  globalLabel,
}) {
  const [exportDropdownOpened, toggleExportDropdown] = React.useState(false)
  const dropdownExport = React.useRef()
  const { t } = useTranslation()
  useOnClickOutside(dropdownExport, () => toggleExportDropdown(false))
  return (
    <div ref={dropdownExport} className="ExportActions">
      <Button icon="download" onClick={() => toggleExportDropdown(true)}>
        {t('export')}
      </Button>
      {exportDropdownOpened && (
        <Dropdown className="ExportActions__Dropdown">
          <Dropdown.Item onClick={onExportGlobal}>
            {globalLabel || t('exportGlobal')}
          </Dropdown.Item>
          {onExportActive && (
            <Dropdown.Item onClick={onExportActive}>
              {activeLabel || t('exportOnly')}
            </Dropdown.Item>
          )}
        </Dropdown>
      )}
    </div>
  )
}
