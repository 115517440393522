import { BreadcrumbItem } from './BreadcrumbItem'

export function Breadcrumb({ path }) {
  return (
    <div className="BreadcrumbWrapper">
      <ul className="Breadcrumb">
        {path.map((content, index) => (
          <BreadcrumbItem
            key={index}
            content={content}
            withSeparator={index > 0}
          />
        ))}
      </ul>
    </div>
  )
}
