import { stopEvent, useOnClickOutside } from '@ttrmz/react-utils'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Dropdown } from '../Dropdown'
import { Icon } from '../Icon'

const NOOP = () => ({})

export function Select({
  options,
  value: valueProp,
  label,
  className,
  getOptionLabel,
  isSelectedOption = NOOP,
  isDisabledOption = NOOP,
  onChange,
  ...props
}) {
  const [open, setOpen] = React.useState(false)
  const inputRef = React.useRef()
  useOnClickOutside(inputRef, () => setOpen(false))

  const handleToggleOptions = () => {
    setOpen(prev => !prev)
  }

  const handleChange = (event, option) => {
    stopEvent(event)
    onChange(option)
    setOpen(false)
  }

  const value =
    !valueProp || typeof valueProp === 'string'
      ? valueProp
      : getOptionLabel(valueProp)

  return (
    <div
      {...props}
      ref={inputRef}
      className={classNames(className, 'Select', {
        'Select--opened': open,
        'Select--filled': value,
      })}
      onClick={handleToggleOptions}
    >
      {label && <span className="Select__label">{label}</span>}

      <span
        className={classNames('Select__value', {
          'Select__value--withLabel': label,
        })}
      >
        {value}
      </span>

      <Icon
        className="Select__doropdonwIcon"
        name={open ? 'sort-asc' : 'sort-desc'}
      />

      {open && (
        <Dropdown className="Select__options">
          {options.map((option, index) => (
            <Dropdown.Item
              key={index}
              active={isSelectedOption(option)}
              disabled={isDisabledOption(option)}
              onClick={event => handleChange(event, option)}
              children={getOptionLabel(option)}
            />
          ))}
        </Dropdown>
      )}
    </div>
  )
}

Select.defaultProps = {
  options: [],
  getOptionLabel: item => item.label,
  isDisabledOption: () => false,
}

Select.propTypes = {
  options: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  isSelectedOption: PropTypes.func.isRequired,
  isDisabledOption: PropTypes.func.isRequired,
}
