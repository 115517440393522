export const keycloakConfig = {
  realm: process.env.REACT_APP_KEYCLOAK_REALM || 'hyperworld',
  url: process.env.REACT_APP_KEYCLOAK_URL || 'http://localhost:8080/auth',
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || 'goldindex',
  'ssl-required': 'external',
  'enable-cors': true,
  'proxy-address-forwarding': true,
}

export const keycloakAdminUrl = `${process.env.REACT_APP_KEYCLOAK_URL}/admin/hyperworld`
