import React from 'react'
import ReactDOM from 'react-dom'
import { LocationProvider } from '@reach/router'
import { I18nextProvider } from 'react-i18next'
import { App } from './app'
import { KeycloakProvider } from './contexts/keycloak'
import { TracksProvider } from './contexts/tracks'
import { LayoutProvider } from './contexts/layout'
import { keycloak, setRefreshToken } from './core/keycloak'
import { LoadingProgress } from './components'
import i18n from './i18n'
import './index.scss'

global.APP_VERSION = process.env.REACT_APP_VERSION
global.APP_NAME = process.env.REACT_APP_NAME

const mountingPoint = document.getElementById(
  process.env.REACT_APP_MOUNTING_POINT_ID,
)

async function renderAppWithKeycloakLogin() {
  try {
    const auth = await keycloak.init({
      onLoad: 'login-required',
      promiseType: 'native',
    })
    if (!auth) {
      return window.location.reload()
    }
    ReactDOM.render(
      <React.StrictMode>
        <React.Suspense fallback={<LoadingProgress />}>
          <I18nextProvider i18n={i18n}>
            <LocationProvider>
              <KeycloakProvider keycloak={keycloak}>
                <LayoutProvider>
                  <TracksProvider>
                    <App />
                  </TracksProvider>
                </LayoutProvider>
              </KeycloakProvider>
            </LocationProvider>
          </I18nextProvider>
        </React.Suspense>
      </React.StrictMode>,
      mountingPoint,
    )

    setRefreshToken()
  } catch (error) {
    console.error('Authenticated Failed', error)
  }
}

renderAppWithKeycloakLogin()
