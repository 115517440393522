import classNames from 'classnames'
import { Icon } from '../Icon'

export function Variation({
  className,
  color,
  value,
  children,
  isInverted,
  ...props
}) {
  if (value == null) {
    return null
  }

  const icon = value ? (
    <Icon
      className="Variation__icon"
      name={`rank-${value < 0 ? 'down' : 'up'}`}
    />
  ) : null

  return (
    <span
      className={classNames('Variation', className, {
        'Variation--up': isInverted ? value < 0 : value > 0,
        'Variation--down': isInverted ? value > 0 : value < 0,
        'Variation--still': !value,
      })}
      {...props}
    >
      {value > 0 ? `+ ${value}` : value || '='}
      {icon}
    </span>
  )
}
