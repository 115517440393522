import React from 'react'

const TRACKS_CONTEXT = React.createContext()

export const useTracks = () => React.useContext(TRACKS_CONTEXT)

export const TracksProvider = ({ children }) => {
  const [currentTrack, setCurrentTrack] = React.useState()

  const value = React.useMemo(() => ({ currentTrack, setCurrentTrack }), [
    currentTrack,
    setCurrentTrack,
  ])

  return <TRACKS_CONTEXT.Provider value={value} children={children} />
}
