import React from 'react'
import { TinyPlayer, RoundedPlayer } from '..'
import { useKeycloak } from '../../contexts/keycloak'

export function TablePlayer({ titleRef }) {
  const { compact } = useKeycloak()

  return compact ? (
    <RoundedPlayer titleRef={titleRef} />
  ) : (
    <TinyPlayer titleRef={titleRef} />
  )
}
