import classNames from 'classnames'
import PropTypes from 'prop-types'

const COLORS = ['grey', 'pink', 'orange']

export function Score({ className, color, ...props }) {
  return (
    <span
      className={classNames('Score', className, {
        [`Score--${color}`]: color,
      })}
      {...props}
    />
  )
}

Score.propTypes = {
  color: PropTypes.oneOf(COLORS),
}
