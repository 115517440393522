import { isEqual } from 'lodash'
import PopperJS from 'popper.js'
import React from 'react'

const DEFAULT_MODIFIERS = {}

export const PLACEMENTS = PopperJS.placements

/**
 * @typedef {object} PopperState
 * @property {object} arrowStyle
 * @property {boolean} outOfBoundaries
 * @property {string} placement
 * @property {object} style
 *
 * @typedef {object} PopperScheduler
 * @property {function(): void} scheduleUpdate
 *
 * @typedef {PopperState & PopperScheduler} PopperReturnedState
 */

/**
 * @param {object} options
 * @param {HTMLElement} options.popper
 * @param {HTMLElement} options.reference
 * @param {HTMLElement} [options.arrow]
 * @param {boolean} [options.eventsEnabled=true]
 * @param {object} [options.modifiers={}]
 * @param {string} [options.placement='bottom']
 * @param {boolean} [options.positionFixed=false]
 * @returns {PopperReturnedState}
 */
export function usePopper({
  popper,
  reference,
  arrow,
  eventsEnabled = true,
  modifiers = DEFAULT_MODIFIERS,
  placement = 'bottom',
  positionFixed = true,
}) {
  const popperJS = React.useRef(null)
  const [state, dispatch] = React.useReducer(reducer, initialState)

  React.useEffect(() => {
    if (reference != null && popper != null) {
      popperJS.current = new PopperJS(reference, popper, {
        placement,
        positionFixed,
        modifiers: {
          ...modifiers,
          arrow: {
            ...modifiers.arrow,
            enabled: arrow != null,
            element: arrow,
          },
          applyStyle: {
            // We apply the styles ourselves.
            enabled: false,
          },
          updateStateModifier: {
            enabled: true,
            // The same as `applyStyle`.
            // https://popper.js.org/popper-documentation.html#modifiers..applyStyle.order
            order: 900,
            fn: data => {
              // The popper element might have lost his parent during the
              // computation of the position.
              // It can be due to a fast removal of the popper.
              // In case it happenned we don't dispatch the computed state.
              if (data.instance.popper.parentElement != null) {
                dispatch({ type: 'UPDATE', data })
              }

              return data
            },
          },
        },
      })

      return () => {
        dispatch({ type: 'RESET' })
        popperJS.current.destroy()
        popperJS.current = null
      }
    }
  }, [arrow, reference, popper, placement, positionFixed, modifiers])

  React.useEffect(() => {
    if (popperJS.current != null) {
      if (eventsEnabled) {
        popperJS.current.enableEventListeners()
      } else {
        popperJS.current.disableEventListeners()
      }
    }
  }, [eventsEnabled])

  const scheduleUpdate = React.useCallback(() => {
    if (popperJS.current != null) {
      popperJS.current.scheduleUpdate()
    }
  }, [])

  React.useLayoutEffect(() => {
    // Schedule an update if the placement has changed.
    // The styles (i.e. margins) of the popper might depend on the placement
    // which can affect its position.
    scheduleUpdate()
  }, [scheduleUpdate, state.placement])

  return React.useMemo(() => ({ ...state, scheduleUpdate }), [
    state,
    scheduleUpdate,
  ])
}

usePopper.PLACEMENTS = PLACEMENTS

/** @type {PopperState} */
const initialState = {
  arrowStyle: {},
  outOfBoundaries: false,
  placement: null,
  style: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    pointerEvents: 'none',
  },
}

/**
 * @param {PopperState} state
 * @param {object} action
 * @returns {PopperState}
 */
function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE': {
      return updateState(state, action.data)
    }

    case 'RESET':
    default: {
      return initialState
    }
  }
}

function updateState(state, newState) {
  // `state` and `newState` might have different references and still be deeply
  // equal due to Popper.js.
  const arrowStyle = getCurrentValue(state.arrowStyle, newState.arrowStyles)
  const outOfBoundaries = newState.hide
  const placement = newState.placement
  const style = getCurrentValue(state.style, newState.styles)

  return arrowStyle === state.arrowStyle &&
    outOfBoundaries === state.outOfBoundaries &&
    placement === state.placement &&
    style === state.style
    ? state
    : { arrowStyle, outOfBoundaries, placement, style }
}

function getCurrentValue(value, newValue) {
  return isEqual(value, newValue) ? value : newValue
}
