import React from 'react'

const LAYOUT_CONTEXT = React.createContext()

export const useLayout = () => React.useContext(LAYOUT_CONTEXT)

export const LayoutProvider = ({ children }) => {
  const [hasSidebar, setSidebar] = React.useState(false)
  const [wave, setWave] = React.useState(null)
  const [selections, setSelections] = React.useState(null)
  const [breadcrumbPath, setBreadcrumbPath] = React.useState([])
  const value = React.useMemo(
    () => ({
      hasSidebar,
      setSidebar,
      wave,
      setWave,
      breadcrumbPath,
      setBreadcrumbPath,
      selections,
      setSelections,
    }),
    [hasSidebar, wave, breadcrumbPath, selections, setSelections],
  )

  return (
    <LAYOUT_CONTEXT.Provider value={value}>{children}</LAYOUT_CONTEXT.Provider>
  )
}
