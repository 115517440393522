import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { composeRefs, watchResize } from 'react-behave'
import { FixedSizeList } from 'react-window'

// import './_VirtualList.scss'

const WATCH_RESIZE_OPTIONS = {
  // Correspond to 1 frame.
  resizeInterval: 16,
}

function VirtualList({
  listProps,

  // Forwarded props.
  refProp,
  ...rest
}) {
  const [size, setSize] = React.useState(null)
  const container = React.useRef(null)

  React.useEffect(() => {
    return watchResize(container.current, setSize, WATCH_RESIZE_OPTIONS)
  }, [])

  return (
    <div {...rest} ref={composeRefs([container, refProp])}>
      {size != null && (
        <FixedSizeList
          {...listProps}
          width={size.width}
          height={size.height}
          className={classNames('VirtualList', listProps.className)}
        />
      )}
    </div>
  )
}

VirtualList.propTypes = {
  listProps: PropTypes.object,
}

VirtualList.defaultProps = {
  listProps: {},
}

const VirtualListRef = React.forwardRef((props, ref) => (
  <VirtualList {...props} refProp={ref} />
))

VirtualListRef.defaultProps = VirtualList.defaultProps

export { VirtualListRef as VirtualList }
