import { navigate, useLocation } from '@reach/router'
import React from 'react'
import { useOnClickOutside } from '@ttrmz/react-utils'
import classnames from 'classnames'

import { useTranslation } from 'react-i18next'
import { useLayout } from '../../contexts/layout'
import { useFormatDate } from '../../core/formatDate'
import { useGetStationWaves } from '../../server/stationsControllers'
import { Icon } from '../Icon'
import { Dropdown } from '../Dropdown'

export function WaveSwitcher() {
  const { t } = useTranslation()
  const { formatDate } = useFormatDate()

  const [wavesDropdownOpened, toggleWavesDropdown] = React.useState(false)
  const dropdownWaves = React.useRef()
  const { wave } = useLayout()
  const { waves } = useGetStationWaves(wave.station.id)
  const { pathname } = useLocation()
  const [, , , ...current] = pathname.split('/')
  const previousWave = waves.find(wa => wa.number === wave.number - 1)
  const nextWave = waves.find(wa => wa.number === wave.number + 1)

  useOnClickOutside(dropdownWaves, () => toggleWavesDropdown(false))

  const wrapperClasses = classnames('WaveSwitcher__current', {
    'WaveSwitcher__current--open': wavesDropdownOpened,
  })

  const chevronLeftClasses = classnames('WaveSwitcher__chevron', {
    'WaveSwitcher__chevron--disabled': !previousWave,
  })
  const chevronRightClasses = classnames('WaveSwitcher__chevron', {
    'WaveSwitcher__chevron--disabled': !nextWave,
  })

  return (
    <div className="WaveSwitcher">
      <Icon
        className={chevronLeftClasses}
        name="chevron-left"
        onClick={() =>
          previousWave &&
          navigate(`/wave/${previousWave.id}/${current.join('/')}`)
        }
      />
      <div
        className={wrapperClasses}
        onClick={() => toggleWavesDropdown(true)}
        ref={dropdownWaves}
      >
        <Icon name="calendar" className="WaveSwitcher__calendar" />
        {`${t('wave')} ${wave.number} ${t('from')} ${formatDate(
          wave.publishedAt,
          {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          },
        )}`}
        {wavesDropdownOpened && (
          <Dropdown className="WaveSwitcher__Dropdown">
            {waves.map(({ number, publishedAt, id }, index) => {
              const currWave = number === wave.number
              return (
                <Dropdown.Item
                  className={classnames('WaveSwitcher__DropdownItem', {
                    'WaveSwitcher__DropdownItem--active': currWave,
                  })}
                  onClick={() => navigate(`/wave/${id}/${current.join('/')}`)}
                  key={index}
                >
                  <span>
                    {`${t('wave')} ${number} - ${formatDate(publishedAt, {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}`}
                  </span>
                  {number === wave.number && (
                    <Icon name="tick" className="WaveSwitcher__tick" />
                  )}
                </Dropdown.Item>
              )
            })}
          </Dropdown>
        )}
      </div>

      <Icon
        className={chevronRightClasses}
        name="chevron-right"
        onClick={() =>
          nextWave && navigate(`/wave/${nextWave.id}/${current.join('/')}`)
        }
      />
    </div>
  )
}
