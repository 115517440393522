import { useFormatDate } from '../../core/formatDate'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from '@reach/router'

export function StationCard({ station, ...props }) {
  const { t } = useTranslation()
  const { formatDate } = useFormatDate()

  return (
    <Link
      to={`/wave/${station.lastWaveID}/overview`}
      className="StationCard"
      {...props}
    >
      <figure className="StationCard__img">
        <img
          src={`https://goldindex.hyperworld.fr/clients/${station.name.toLowerCase()}@2x.png`}
          alt={station.name}
        />
      </figure>

      <h3 className="StationCard__name">{station.name}</h3>

      <label className="StationCard__label">{t('totalWaves')}</label>
      <span className="StationCard__value">{station.nbWaves}</span>

      <label className="StationCard__label">{t('publishOn')}</label>
      <span className="StationCard__value">
        {formatDate(station.lastPublishDate)}
      </span>
    </Link>
  )
}

StationCard.propTypes = {
  station: PropTypes.shape({
    name: PropTypes.string.isRequired,
    nbWaves: PropTypes.number.isRequired,
    lastPublishDate: PropTypes.string.isRequired,
  }),
}
