import { stopEvent } from '@ttrmz/react-utils'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Icon } from '../../Icon'
import { warn } from '../warnings'
const COLORS = ['green', 'grey', 'light-grey', 'red', 'white', 'yellow']
const SIZES = ['medium', 'small']

export default function Chip({
  color,
  active,
  size,
  label,
  children,
  onRemove,
  onClick,
  innerRef,
  component: Component,
  className,
  removeActionProp,
  ...rest
}) {
  React.useEffect(() => {
    if (label != null && children != null) {
      warn('<Chip /> Only one of `label` and `children` should be used.')
    } else if (label == null && children == null) {
      warn('<Chip /> At least one of `label` or `children` should be used.')
    }
  }, [label, children])

  const handleOnRemove = event => {
    stopEvent(event)
    onRemove()
  }

  const chipClasses = classNames(
    className,
    'Chip',
    `Chip--${size}`,
    `Chip--${color}`,
    {
      'Chip--clickable': onClick,
      [`Chip--${color}--active`]: active,
      [`Chip--${color}--clickable`]: onClick,
      'Chip--active': active,
    },
  )

  return (
    <Component
      className={chipClasses}
      onClick={onClick}
      ref={innerRef}
      {...rest}
    >
      {label != null && <span className="Chip__label">{label}</span>}

      {children != null && (
        <span className="Chip__richContent">{children}</span>
      )}

      {onRemove && (
        <Icon
          name="close"
          className="Chip__removeIcon"
          onClick={handleOnRemove}
        />
      )}
    </Component>
  )
}

Chip.propTypes = {
  color: PropTypes.oneOf(COLORS),
  size: PropTypes.oneOf(SIZES),
  label: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  active: PropTypes.bool,
  onRemove: PropTypes.func,
  onClick: PropTypes.func,
  component: PropTypes.elementType,
  className: PropTypes.string,
  removeActionProp: PropTypes.object,
}

Chip.defaultProps = {
  size: 'medium',
  active: false,
  color: 'light-grey',
  component: 'span',
  removeActionProp: {},
}

const ChipWithForwardRef = React.forwardRef((props, ref) => (
  <Chip {...props} innerRef={ref} />
))

ChipWithForwardRef.defaultProps = Chip.defaultProps
ChipWithForwardRef.SIZES = SIZES
ChipWithForwardRef.COLORS = COLORS

export { ChipWithForwardRef as Chip }
