import { useAuthenticatedFetch } from '../hooks/useAuthenticatedFetch'

export function useGetWave(id) {
  const { response, ...rest } = useAuthenticatedFetch(`/wave/${id}`)

  return { wave: response, ...rest }
}

export function useGetWaveDetails(id, options = {}, queryParams = {}) {
  const { response, error, ...rest } = useAuthenticatedFetch(
    `/wave/${id}/details`,
    {
      method: 'POST',
      ...options,
    },
    queryParams,
  )
  return { details: response, ...rest }
}

export function useGetWaveDetailsScore(id, options = {}, queryParams = {}) {
  const { response, error, ...rest } = useAuthenticatedFetch(
    `/wave/${id}/details/score`,
    {
      method: 'POST',
      ...options,
    },
    queryParams,
  )
  return { details: response, ...rest }
}

export function useGetWaveTrends(id, options = {}, queryParams = {}) {
  const { response, error, ...rest } = useAuthenticatedFetch(
    `/wave/${id}/trends`,
    {
      method: 'POST',
      ...options,
    },
    queryParams,
  )
  return { trends: response, ...rest }
}
