import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Tooltify } from '../../Tooltify/Tooltify'

function TableCellText({
  className,
  component: Component,
  innerRef,
  children,
  ...rest
}) {
  return (
    <Component
      {...rest}
      className={classNames(className, 'TableCellText')}
      ref={innerRef}
    >
      <Tooltify children={children} />
    </Component>
  )
}

const TableCellTextWithRef = React.forwardRef((props, ref) => (
  <TableCellText innerRef={ref} {...props} />
))

export { TableCellTextWithRef as TableCellText }

TableCellText.defaultProps = {
  component: 'span',
}

TableCellText.propTypes = {
  component: PropTypes.elementType,
}
