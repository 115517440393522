import classNames from 'classnames'
import { Slide, ToastContainer } from 'react-toastify'

export function SnackbarContainer() {
  return (
    <ToastContainer
      transition={Slide}
      position="bottom-right"
      className={classNames(
        'SnackbarContainer',
        `SnackbarContainer--bottom-right`,
      )}
      toastClassName="Snackbar"
      bodyClassName="Snackbar__body"
      progressClassName="Snackbar__progressBar"
      hideProgressBar
      closeButton={false}
    />
  )
}
