import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Tooltip } from '../Tooltip'

export function Tooltify({
  children,
  lineCount,
  className,
  tooltipProps,
  style,
  ...rest
}) {
  return (
    <Tooltip {...tooltipProps} content={children} onlyIfOverflow>
      <span
        {...rest}
        className={classNames(className, {
          Tooltify: lineCount === 1,
          MultilineTooltify: lineCount > 1,
        })}
        style={{
          ...style,
          WebkitLineClamp: lineCount > 1 ? lineCount : undefined,
        }}
      >
        {children}
      </span>
    </Tooltip>
  )
}

Tooltify.propTypes = {
  /**
   * The text to tooltify in case of overflow.
   */
  children: PropTypes.node,

  /**
   * The number of lines to display.
   */
  lineCount: PropTypes.number,

  /**
   * Props to apply on the `Tooltip`.
   */
  tooltipProps: PropTypes.object,
}

Tooltify.defaultProps = {
  lineCount: 1,
}
