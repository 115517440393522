import PropTypes from 'prop-types'
import React from 'react'
import { TABLE_CONTEXT } from './tableContext'

export function TableBody({ children }) {
  const value = React.useMemo(() => ({ section: 'body', sticky: false }), [])

  return <TABLE_CONTEXT.Provider value={value} children={children} />
}

TableBody.propTypes = {
  children: PropTypes.node,
}
