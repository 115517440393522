import React from 'react'

export function useStateOrProps(defaultValue, propsState) {
  const state = React.useState(defaultValue)

  // We want to support null values (propsState[0]).
  if (propsState[0] !== undefined && propsState[1] != null) {
    return propsState
  }

  return state
}
