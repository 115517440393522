import { getBearerTokenHeaders } from '../core/getBearerTokenHeaders'
import { useFetch } from '../hooks/useFetch'
import { useLazyFetch } from '../hooks/useLazyFetch'

export function useGetUser(token) {
  const { response, ...rest } = useFetch(
    `${process.env.REACT_APP_API_DOMAIN}api/hit/users/me`,
    {
      headers: getBearerTokenHeaders(token),
    },
  )

  return { me: response, ...rest }
}

export function useEditCompactMode(token) {
  const { lazyFetch, ...rest } = useLazyFetch(
    `${process.env.REACT_APP_API_DOMAIN}api/hit/users/me`,
  )

  const editCompactMode = compact =>
    lazyFetch({
      headers: getBearerTokenHeaders(token),
      method: 'POST',
      body: JSON.stringify({ compact }),
    })

  return { editCompactMode, ...rest }
}
