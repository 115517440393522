import { useOnClickOutside } from '@ttrmz/react-utils'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, Numeric, Table } from '../../components'
import { INVERTED_REFS, NUMERIC_CELL_WEIGHT } from '../../config/constants'
import { DATA_TYPES } from '../../config/dataTypes'
import { useKeycloak } from '../../contexts/keycloak'
import { useTags } from '../../hooks/useTags'
import { Icon } from '../Icon'
import { Placeholder } from '../Placeholder'
import { TableCellText } from '../Table/TableCellText'
import { Tooltip } from '../Tooltip'

const SCORE_TOOLTIPS = {
  green: 'aboveAverageDeviation',
  red: 'underAverageDeviation',
}

const SCORE_TOOLTIPS_INVERTED = {
  red: SCORE_TOOLTIPS.green,
  green: SCORE_TOOLTIPS.red,
}

const getDetailColor = (ref, value, { average, deviation }) => {
  if (INVERTED_REFS.includes(ref)) {
    if (value <= (average - deviation >= 0 ? average - deviation : 0))
      return 'green'
    if (value >= average + deviation) return 'red'
  }

  if (value >= average + deviation) return 'green'
  if (value <= average - deviation) return 'red'

  return null
}

const getAccessor = (column, score) => ({
  label: column.name,
  accessor: (title, global) => {
    const value = title.values[column.id]
    const color = getDetailColor(score?.ref || column.ref, value, {
      average: global.average[column.id],
      deviation: global.deviation?.[column.id],
    })

    const tooltipMessage = INVERTED_REFS.includes(score?.ref || column.ref)
      ? SCORE_TOOLTIPS_INVERTED[color]
      : SCORE_TOOLTIPS[color]

    const Component = tooltipMessage ? Tooltip : React.Fragment
    const props = tooltipMessage ? { content: tooltipMessage } : {}

    return (
      <Component {...props}>
        <Numeric color={color}>{value}</Numeric>
      </Component>
    )
  },
  align: 'center',
  weight: NUMERIC_CELL_WEIGHT,
  sortField: `values.${column.id}`,
  defaultSortOrder: INVERTED_REFS.includes(score?.ref || column.ref)
    ? 'asc'
    : 'desc',
})

const getTagAccessor = tag => ({
  label: tag.key,
  accessor: title => {
    const value = title.tags[tag.id]

    return <Numeric>{value}</Numeric>
  },
  align: 'center',
  weight: NUMERIC_CELL_WEIGHT,
  sortField: `tags.${tag.id}`,
  defaultSortOrder: 'desc',
})

const COLUMNS = [DATA_TYPES.rank, DATA_TYPES.title, DATA_TYPES.artist]

export function RichTable({
  deviationLabel,
  averageLabel,
  global,
  values,
  tab,
  columns,
  activeColumns,
  onChangeActiveColumns,
  selectedRows,
  onClickRow,
  onClicAllkRows,
  onSort,
  sort,
  loading,
  score,
  stickyStyle,
  renderTotal,
  ...props
}) {
  const [dropdownColumnsOpened, setDropdownColumns] = React.useState(false)
  const dropdownColumn = React.useRef()
  const { t } = useTranslation()
  const { compact } = useKeycloak()

  const tags = useTags()
  const tagsIds = tags.map(({ id }) => id)

  useOnClickOutside(dropdownColumn, () => setDropdownColumns(false))

  const displayedColumns = activeColumns || columns

  const orderedColumns = [
    ...COLUMNS,
    ...displayedColumns.map(column =>
      tagsIds.includes(column.id)
        ? getTagAccessor(column)
        : getAccessor(column, score),
    ),
  ]

  const sortedTitles =
    sort.sortField === DATA_TYPES.rank.sortField
      ? orderBy(values, ['rank', 'name'], [sort.sortOrder, 'asc'])
      : values

  const deviatoinTooltipProps = !deviationLabel
    ? {
        content: t('deviationDef'),
      }
    : {}
  const DeviationTooltip = !deviationLabel ? Tooltip : React.Fragment

  const total =
    typeof renderTotal === 'function'
      ? renderTotal(values)
      : `${values.length} ${t(`titles`)}`

  return (
    <Table compact={compact} {...props}>
      <Table.Row className="RichTable__totalRow">
        <Table.Cell
          className="RichTable__totalValue"
          align="left"
          children={total}
        />

        <Table.Cell className="RichTable__actions" align="right">
          {onClicAllkRows && (
            <TableCellText onClick={onClicAllkRows} component="button">
              {selectedRows.length === values.length
                ? t('selectNone')
                : t('selectAll')}
            </TableCellText>
          )}

          {onChangeActiveColumns && activeColumns && (
            <TableCellText
              ref={dropdownColumn}
              component="button"
              onClick={() => setDropdownColumns(true)}
            >
              {t('columns') + ' '}
              <Icon
                className="RichTable__actionsIcon"
                name={dropdownColumnsOpened ? 'sort-asc' : 'sort-desc'}
              />
              {dropdownColumnsOpened && (
                <Dropdown className="RichTable__dropdownColumns">
                  {columns.map(column => (
                    <Dropdown.Item
                      onClick={() => onChangeActiveColumns(column)}
                      key={column.id}
                      active={activeColumns
                        .map(({ id }) => id)
                        .includes(column.id)}
                    >
                      {column.name} {/* Brice Comment */}
                    </Dropdown.Item>
                  ))}

                  {tags.map(tag => (
                    <Dropdown.Item
                      onClick={() => onChangeActiveColumns(tag)}
                      key={tag.id}
                      active={activeColumns
                        .map(({ id }) => id)
                        .includes(tag.id)}
                    >
                      {tag.key}
                    </Dropdown.Item>
                  ))}
                </Dropdown>
              )}
            </TableCellText>
          )}
        </Table.Cell>
      </Table.Row>

      <Table.Head sticky stickyStyle={stickyStyle}>
        {tab}

        <Table.Row className="RichTable__average">
          <Table.Cell
            className="RichTable__averageTitle"
            align="left"
            weight={DATA_TYPES.title.weight}
          >
            <Table.TextContent>
              {averageLabel || t('averages')}
            </Table.TextContent>
          </Table.Cell>

          {/* preserve alignement */}
          <Table.Cell width={DATA_TYPES.rank.width} />
          <Table.Cell weight={0} />

          {displayedColumns.map(({ id }) => (
            <Table.Cell
              key={id}
              weight={NUMERIC_CELL_WEIGHT}
              className="RichTable__averageValue"
            >
              {loading ? (
                <Placeholder />
              ) : tagsIds.includes(id) ||
                typeof global?.average?.[id] !== 'number' ? (
                '-'
              ) : (
                Math.round(global?.average?.[id])
              )}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          <Table.Cell
            className="RichTable__deviationTitle"
            align="left"
            weight={DATA_TYPES.title.weight}
          >
            <DeviationTooltip {...deviatoinTooltipProps}>
              <Table.TextContent>
                {deviationLabel || t('deviation')}
              </Table.TextContent>
            </DeviationTooltip>
          </Table.Cell>

          {/* preserve alignement */}
          <Table.Cell width={DATA_TYPES.rank.width} />
          <Table.Cell weight={0} />

          {displayedColumns.map(({ id }) => (
            <Table.Cell
              key={id}
              weight={NUMERIC_CELL_WEIGHT}
              className="RichTable__deviationValue"
            >
              {loading ? (
                <Placeholder />
              ) : tagsIds.includes(id) ||
                typeof global?.deviation?.[id] !== 'number' ? (
                '-'
              ) : (
                Math.round(global.deviation?.[id])
              )}
            </Table.Cell>
          ))}
        </Table.Row>

        <Table.Row>
          {orderedColumns.map(column =>
            column.sortField === 'rank' ? (
              <>
                <Table.Cell
                  align="left"
                  key={column.label}
                  weight={DATA_TYPES.title.weight}
                >
                  {COLUMNS.map(subColumn => (
                    <Table.SortableTextContent
                      onClick={() =>
                        onSort({
                          sortField: subColumn.sortField,
                          sortOrder: subColumn.defaultSortOrder || 'asc',
                        })
                      }
                      sortDirection={
                        sort.sortField === subColumn.sortField
                          ? sort.sortOrder
                          : 'default'
                      }
                      children={t(subColumn.label)}
                    />
                  ))}
                </Table.Cell>

                {/* preserve alignement */}
                <Table.Cell weight={0} />
                <Table.Cell width={DATA_TYPES.rank.width} />
              </>
            ) : ['artist', 'name'].includes(column.sortField) ? null : (
              <Table.Cell
                align={column.align}
                key={column.label}
                weight={column.weight}
                width={column.width}
              >
                <Table.SortableTextContent
                  onClick={() =>
                    onSort({
                      sortField: column.sortField,
                      sortOrder: column.defaultSortOrder || 'asc',
                    })
                  }
                  sortDirection={
                    sort.sortField === column.sortField
                      ? sort.sortOrder
                      : 'default'
                  }
                  children={column.label}
                />
              </Table.Cell>
            ),
          )}
        </Table.Row>
      </Table.Head>

      <Table.Body>
        {sortedTitles.length > 0 ? (
          sortedTitles.map(title => (
            <Table.Row
              key={title.id}
              selected={!loading && selectedRows.includes(title.id)}
              onClick={() => onClickRow(title)}
            >
              {orderedColumns.map(column => (
                <Table.Cell
                  align={column.align}
                  key={column.label}
                  weight={column.weight}
                  width={column.width}
                  children={
                    loading ? <Placeholder /> : column.accessor(title, global)
                  }
                />
              ))}
            </Table.Row>
          ))
        ) : (
          <div className="RichTable--empty">{t('noPlaylist')}</div>
        )}
      </Table.Body>
    </Table>
  )
}

RichTable.defaultProps = {
  selectedRows: [],
  onClicAllkRows: () => {},
  onClickRow: () => {},
  onSort: () => {},
  sort: { sortField: null, sortOrder: null },
  stickyStyle: {},
}

RichTable.propTypes = {
  stickyStyle: PropTypes.object,
  values: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  global: PropTypes.object.isRequired,
  activeColumns: PropTypes.array,
  onChangeActiveColumns: PropTypes.func,
  selectedRows: PropTypes.array,
  onClickRow: PropTypes.func,
  onClicAllkRows: PropTypes.func,
  tab: PropTypes.node,
  onSort: PropTypes.func,
  sort: PropTypes.object,
  loading: PropTypes.bool,
  renderTotal: PropTypes.func,
}
