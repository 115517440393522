import React from 'react'
import { LoadingProgress } from '../components'
import { SELECTIONS_TYPES } from '../config/constants'
import { useGetSelections } from '../server/selectionsControllers'
import { useGetWave } from '../server/wavesControllers'
import { useLayout } from './layout'
import { SCORES_TYPES } from '../config/constants'

const WAVE_CONTEXT = React.createContext()

export const useWave = () => React.useContext(WAVE_CONTEXT)

export const WaveProvider = ({ children, waveId }) => {
  const { setWave, setSelections } = useLayout()
  const { wave, loading, called, refetch } = useGetWave(waveId)
  const {
    selections: selectionsResponses,
    refetch: refetchSelections,
    loading: loadingSelections,
  } = useGetSelections(waveId)

  React.useEffect(() => {
    if (called && !loading) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waveId])

  const selections = React.useMemo(
    () =>
      selectionsResponses?.filter(
        selection => selection.type === SELECTIONS_TYPES.selection,
      ) || [],
    [selectionsResponses],
  )

  const scoresWithTypes = React.useMemo(
    () =>
      wave?.scores?.map(score => ({
        ...score,
        name: `${score.name} ${
          SCORES_TYPES[score.ref] === 'percent' ? '(%)' : ''
        }`,
      })) || [],
    [wave],
  )

  React.useEffect(() => {
    if (wave) {
      setSelections(selections)
      setWave(wave)
    }
  }, [wave, selections, setSelections, setWave])

  const vues = React.useMemo(
    () =>
      selectionsResponses?.filter(
        selection => selection.type === SELECTIONS_TYPES.expert,
      ) || [],
    [selectionsResponses],
  )

  const waveWithScoreTypes = React.useMemo(
    () => ({ ...wave, scores: scoresWithTypes }),
    [wave, scoresWithTypes],
  )

  const value = React.useMemo(
    () => ({
      wave: waveWithScoreTypes,
      selections,
      refetchSelections,
      loadingSelections,
      vues,
    }),
    [
      selections,
      refetchSelections,
      loadingSelections,
      vues,
      waveWithScoreTypes,
    ],
  )
  if (!wave || loading || !selections) return <LoadingProgress />

  return <WAVE_CONTEXT.Provider value={value} children={children} />
}
