// This file is generated by build:icons script. Changes will be overridden.

export const ICONS = [
"add",
"bubble",
"bulb",
"calendar",
"cancel",
"charts",
"checkbox",
"chevron-left",
"chevron-right",
"close",
"details",
"download",
"edit",
"expert",
"filter",
"home",
"like-e",
"like-f",
"link",
"list",
"new",
"pause",
"play",
"rank-down",
"rank-keep",
"rank-up",
"ranking",
"rename",
"rocket",
"save",
"scores",
"search",
"selection",
"selection-add",
"selection-add",
"copy",
"selection-delete",
"sort-asc",
"sort-default",
"sort-desc",
"switch",
"tick",
"trash",
"users",
"versus",
];
