import { Link, navigate, useLocation } from '@reach/router'
import { stopEvent } from '@ttrmz/react-utils'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useFavourites } from '../../contexts/favourites'
import { useLayout } from '../../contexts/layout'
import { useVersus } from '../../contexts/versus'
import { showSnackbar } from '../../core/showSnackbar'
import { Icon } from '../Icon'
import { TitleType } from '../TitleType'
import { Tooltip } from '../Tooltip'

export function Title({
  className,
  title,
  display,
  noActions,
  hideVersus,
  ...props
}) {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [, , waveId] = pathname.split('/')
  const { titles, toggleFavourite, favourites } = useFavourites()
  const { onSelectTitle } = useVersus()
  const { selections } = useLayout() // need to use layout because of titles in search from topbar

  const inSelections = selections.filter(
    selection =>
      selection?.data?.titles?.includes(title.id) &&
      !selection?.data?.isFavourites,
  )

  const isFav = titles?.includes(title.id)
  const isColumnDisplayed = display === 'column'

  const handleToggleFav = event => {
    stopEvent(event)
    toggleFavourite(title.id)

    if (!isFav) {
      showSnackbar(t('addedToFav'), {
        onAction: () => navigate(`/wave/${waveId}/selections/${favourites.id}`),
        actionLabel: 'Voir',
      })
    } else {
      showSnackbar(t('removeFromFav'), {
        onAction: () => toggleFavourite(title.id),
        actionLabel: t('cancel'),
      })
    }
  }

  const selectTitleVersus = event => {
    stopEvent(event)
    onSelectTitle(title)
  }

  return (
    <div
      className={classNames('Title', className, {
        'Title--column': isColumnDisplayed,
      })}
      {...props}
    >
      {!isColumnDisplayed ? (
        <Tooltip
          content={[
            <span className="Title__tooltipName">{title.name}</span>,
            <span className="Title__tooltipArtist">
              - {title.artist.toLowerCase()}
            </span>,
          ]}
        >
          <div className="Title--row">
            <span className="Title__name">
              <b>{title.name}</b>
            </span>

            <span className="Title__name">{title.artist.toLowerCase()}</span>
          </div>
        </Tooltip>
      ) : (
        <span className="Title__name Title__name--column">
          <span>
            <b>{title.name}</b>
          </span>
          <span>{title.artist.toLowerCase()}</span>
        </span>
      )}
      {!noActions && (
        <div className={classNames('Title__actions')}>
          <TitleType type={title.type} display={display} />

          <button
            className={classNames('Title__link', {
              'Title__link--persist': isFav,
            })}
            onClick={handleToggleFav}
          >
            <Icon name={isFav ? 'like-f' : 'like-e'} />
          </button>

          {!!inSelections.length && (
            <Tooltip
              content={inSelections.map(({ name }) => (
                <p className="Title__selection">{name}</p>
              ))}
            >
              <span className="Title__link">
                <Icon name="selection" />
              </span>
            </Tooltip>
          )}

          {!hideVersus && (
            <button
              className={classNames('Title__link', {})}
              onClick={selectTitleVersus}
            >
              <Icon name="versus" />
            </button>
          )}

          <Link
            to={`/wave/${waveId}/title/${title.id}`}
            className={classNames('Title__link')}
          >
            <Icon name="link" />
          </Link>
        </div>
      )}
    </div>
  )
}
