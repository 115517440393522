import { useKeycloak } from '../contexts/keycloak'
import { getBearerTokenHeaders } from '../core/getBearerTokenHeaders'
import { useFetch } from './useFetch'

export function useAuthenticatedFetch(path, options = {}, queryParams = {}) {
  const { token } = useKeycloak()

  return useFetch(
    `${process.env.REACT_APP_API_DOMAIN}api/hit${path}`,
    {
      ...options,
      headers: {
        ...getBearerTokenHeaders(token),
        ...options?.headers,
      },
    },
    queryParams,
  )
}
