import React from 'react'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { useWave } from './wave'
import { showSnackbar } from '../core/showSnackbar'
import { getUniqueID } from '../hooks/useID'

const MAX_TITLES = 6

const VersusContext = React.createContext({})

export function useVersus() {
  return React.useContext(VersusContext)
}

export function VersusContextProvider({ value, ...rest }) {
  const {
    wave: { id },
  } = useWave()
  const { t } = useTranslation()
  const [selectedTitles, setSelectedTitles] = React.useState([])

  React.useEffect(() => {
    const oldSelectedTitles = localStorage.getItem(`selectedTitles-${id}`)
    if (oldSelectedTitles) {
      setSelectedTitles(JSON.parse(oldSelectedTitles))
    }
  }, [id])

  React.useEffect(() => {
    localStorage.setItem(`selectedTitles-${id}`, JSON.stringify(selectedTitles))
  }, [selectedTitles, id])

  const onSelectTitle = React.useCallback(
    title => {
      return setSelectedTitles(titles => {
        const isSelected = titles.find(
          selectedTitle => selectedTitle.id === title.id,
        )
        const toastId = getUniqueID()
        if (isSelected) {
          showSnackbar(t('removedFromComparator'), { toastId })
          return titles.filter(elmt => elmt.id !== title.id)
        }
        if (titles.length === MAX_TITLES) {
          showSnackbar(`${MAX_TITLES} ${t('maxCompareTitles')}`, { toastId })
          return titles
        }
        showSnackbar(
          `${t('addedToComparator')} ${titles.length + 1}/${MAX_TITLES}`,
          {
            toastId,
            onAction: () => navigate(`/wave/${id}/compare`),
            actionLabel: t('see'),
          },
        )
        return [...titles, title]
      })
    },
    [t],
  )

  const onDeleteAllTitles = React.useCallback(() => {
    setSelectedTitles([])
  }, [])

  const values = React.useMemo(
    () => ({
      selectedTitles,
      onSelectTitle,
      onDeleteAllTitles,
    }),
    [selectedTitles, onSelectTitle, onDeleteAllTitles],
  )

  return <VersusContext.Provider value={values} {...rest} />
}
