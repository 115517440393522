import React from 'react'

export function useLazyFetch(url) {
  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [called, setCalled] = React.useState(0)

  const lazyFetch = async options => {
    setLoading(true)

    try {
      const res = await fetch(`${url}`, options)
      const json = await res.json()

      setLoading(false)
      setCalled(prev => prev + 1)

      return json
    } catch (error) {
      setError(error)
    }
  }

  return { error, loading, called, lazyFetch, setLoading }
}
