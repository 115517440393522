import { stopEvent } from '@ttrmz/react-utils'
import classNames from 'classnames'
import { useWave } from '../../contexts/wave'
import React from 'react'
import { useTracks } from '../../contexts/tracks'
import { Icon } from '../Icon'
import fallbackCover from './fallback.svg'

export function TinyPlayer({
  titleRef,
  className,
  onClick,
  style = {},
  onClickPlay,
  playing,
  onClose,
  ...props
}) {
  const { wave } = useWave() // from context
  const [play, setPlay] = React.useState(false)
  const [currentTime, setCurrentTime] = React.useState(0)
  const [duration, setDurarion] = React.useState(0)
  const audioRef = React.useRef()

  const { currentTrack, setCurrentTrack } = useTracks()

  // Debug override
  const assetUrl =
    process.env.NODE_ENV === 'development'
      ? 'https://staging.hitindex.hyperworld.fr/hooks/'
      : `${process.env.REACT_APP_API_DOMAIN}hooks/`
  const audioUrl = `${assetUrl}${wave.station.name}_hit_${titleRef}.mp3`
  const coverUrl = `${assetUrl}${wave.station.name}_hit_${titleRef}.jpg`

  const onPlay = event => {
    if (onClickPlay) return onClickPlay()
    stopEvent(event)

    let currentTimeInterval = null
    const audio = audioRef.current

    setPlay(!play)

    if (!play) {
      if (currentTrack) {
        currentTrack.pause()
        currentTrack.currentTime = 0
      }
      currentTimeInterval = setInterval(() => {
        setCurrentTime(audio.currentTime)
      }, 100)

      setCurrentTrack(audio)
      setDurarion(audio.duration)

      return audio.play()
    }

    audio.pause()
    audio.currentTime = 0
    setCurrentTime(0)
    clearInterval(currentTimeInterval)
  }

  React.useEffect(() => {
    if (currentTime === 0) setPlay(false)
  }, [currentTime])

  const onEnd = () => {
    setPlay(false)
  }

  const progress = play ? (currentTime / duration) * 100 : 0

  const isPlaying = onClickPlay ? playing : play

  return (
    <div
      className={classNames('TinyPlayer', className)}
      onClick={onPlay}
      style={{
        ...style,
        backgroundImage: `url(${coverUrl}), url(${fallbackCover})`,
      }}
      {...props}
    >
      {onClose && (
        <Icon
          name="close"
          className="TinyPlayer__close"
          onClick={() => onClose()}
        />
      )}
      <Icon className="TinyPlayer__icon" name={isPlaying ? 'pause' : 'play'} />

      <div
        className="TinyPlayer__progress"
        style={{ height: `${progress}%` }}
      />

      <audio
        className="TinyPlayer__audio"
        ref={audioRef}
        onEnded={onEnd}
        src={audioUrl}
      />
    </div>
  )
}
