import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '..'
import { NUMERIC_CELL_WIDTH } from '../../config/constants'
import { DATA_TYPES } from '../../config/dataTypes'
import { useKeycloak } from '../../contexts/keycloak'
import { getDiff } from '../../core/getDif'
import { LoadingProgress } from '../LoadingProgress'
import { Numeric } from '../Numeric'
import { Placeholder } from '../Placeholder'
import { Progress } from '../Progress'
import { Score } from '../Score'
import { TableCellText } from '../Table/TableCellText'

const COLUMNS = [DATA_TYPES.title, DATA_TYPES.artist]

const COLORS_ORDER = ['pink', 'orange']

const DIFF_SORT_FIELD = 'diff'

const getAccessor = (column, index) => ({
  label: column.name,
  accessor: title => {
    const value = Math.round(title.values[column.id])
    const color = COLORS_ORDER[index]

    return <Score color={color}>{value}</Score>
  },
  align: 'center',
  width: NUMERIC_CELL_WIDTH,
  sortField: `values.${column.id}`,
  defaultSortOrder: 'desc',
})

const getColumns = (compare, values) => {
  const progress = {
    label: '',
    accessor: title => {
      const index = values.findIndex(({ id }) => title.id === id)

      return (
        <div className="VersusTable__progress">
          {Object.entries(values[index].values).map(([key, value], index) => (
            <Progress key={key} value={value} color={COLORS_ORDER[index]} />
          ))}
        </div>
      )
    },
    align: 'left',
    weight: 3,
  }

  if (compare.length > 1) {
    const diff = {
      label: 'Diff',
      accessor: title => {
        const value = getDiff(title.values, compare)
        return (
          <Numeric
            children={
              value === 0
                ? '='
                : value > 0
                ? `+ ${value}`
                : `- ${Math.abs(value)}`
            }
          />
        )
      },
      align: 'center',
      width: NUMERIC_CELL_WIDTH,
      sortField: DIFF_SORT_FIELD,
      defaultSortOrder: 'desc',
    }

    return [...COLUMNS, progress, ...compare.map(getAccessor), diff]
  }

  return [...COLUMNS, progress, getAccessor(compare[0])]
}

export function VersusTable({
  values,
  originalValues,
  compare,
  tab,
  selectedRows,
  onClickRow,
  onClicAllkRows,
  onSort,
  sort,
  loading,
}) {
  const { t } = useTranslation()
  const { compact } = useKeycloak()

  const handleClickAll = () => {
    if (selectedRows.length === values.length) {
      onClicAllkRows([])
    } else {
      onClicAllkRows(values.map(({ id }) => id))
    }
  }

  const orderedColumns = getColumns(compare, values)

  const sortedTitles =
    sort.sortField === DIFF_SORT_FIELD
      ? orderBy(
          originalValues,
          [title => getDiff(title.values, compare), 'name'],
          [sort.sortOrder, 'asc'],
        )
      : originalValues

  return (
    <>
      {loading && <LoadingProgress />}

      <Table compact={compact}>
        <Table.Row className="VersusTable__totalRow">
          <Table.Cell className="VersusTable__totalValue" align="left">
            {values.length} {t('titles')}
          </Table.Cell>

          <Table.Cell className="VersusTable__actions" align="right">
            {onClicAllkRows && (
              <TableCellText onClick={handleClickAll} component="button">
                {selectedRows.length === values.length
                  ? t('selectNone')
                  : t('selectAll')}
              </TableCellText>
            )}
          </Table.Cell>
        </Table.Row>

        <Table.Head sticky>
          {tab}

          <Table.Row>
            {orderedColumns.map(column =>
              column.sortField === 'name' ? (
                <>
                  <Table.Cell align="left" key={column.label} weight={1}>
                    {COLUMNS.map(subColumn => (
                      <Table.SortableTextContent
                        onClick={() =>
                          onSort({
                            sortField: subColumn.sortField,
                            sortOrder: subColumn.defaultSortOrder || 'asc',
                          })
                        }
                        sortDirection={
                          sort.sortField === subColumn.sortField
                            ? sort.sortOrder
                            : 'default'
                        }
                        children={t(subColumn.label)}
                      />
                    ))}
                  </Table.Cell>
                </>
              ) : ['artist'].includes(column.sortField) ? (
                <Table.Cell weight={0} />
              ) : (
                <Table.Cell
                  align={column.align}
                  key={column.label}
                  weight={column.weight}
                  width={column.width}
                >
                  <Table.SortableTextContent
                    onClick={() =>
                      onSort({
                        sortField: column.sortField,
                        sortOrder: column.defaultSortOrder || 'asc',
                      })
                    }
                    sortDirection={
                      sort.sortField === column.sortField
                        ? sort.sortOrder
                        : 'default'
                    }
                    children={column.label}
                  />
                </Table.Cell>
              ),
            )}
          </Table.Row>
        </Table.Head>

        <Table.Body>
          {sortedTitles ? (
            sortedTitles.map(title => (
              <Table.Row
                key={title.id}
                selected={!loading && selectedRows.includes(title.id)}
                onClick={() => onClickRow(title.id)}
              >
                {orderedColumns.map(column => (
                  <Table.Cell
                    align={column.align}
                    key={column.label}
                    weight={column.weight}
                    width={column.width}
                    children={
                      loading ? <Placeholder /> : column.accessor(title)
                    }
                  />
                ))}
              </Table.Row>
            ))
          ) : (
            <div className="VersusTable--empty">{t('noPlaylist')}</div>
          )}
        </Table.Body>
      </Table>
    </>
  )
}

VersusTable.defaultProps = {
  selectedRows: [],
  onClicAllkRows: () => {},
  onClickRow: () => {},
  onSort: () => {},
  sort: { sortField: null, sortOrder: null },
}

VersusTable.propTypes = {
  values: PropTypes.array.isRequired,
  originalValues: PropTypes.array.isRequired,
  compare: PropTypes.array.isRequired,
  selectedRows: PropTypes.array,
  onClickRow: PropTypes.func,
  onClicAllkRows: PropTypes.func,
  tab: PropTypes.node,
  onSort: PropTypes.func,
  sort: PropTypes.object,
  loading: PropTypes.bool,
}
