export function downloadCSV(response, name) {
  const options = { filename: `${name}`, type: 'csv' }
  response.then(data => {
    var downloadLink = document.createElement('a')
    var blob = new Blob(['\ufeff', data])
    var url = URL.createObjectURL(blob)
    downloadLink.href = url
    downloadLink.download = `${options.filename}.${options.type}` //Name the file here
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  })
}
