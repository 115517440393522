import classNames from 'classnames'
import { uniqBy } from 'lodash'
import matchSorter from 'match-sorter'
import PropTypes from 'prop-types'
import React from 'react'
import { composeHandlers } from 'react-behave'
import { callPropAccessor } from './callPropAccessor'
import { ensureArray } from './ensureArray'
import { stopEvent } from '@ttrmz/react-utils'
import { useComposedRefs } from '../../hooks/useComposedRefs'
import { useID } from '../../hooks/useID'
import { Adornment } from './Adornment'
import { Chip } from './Chip'
import { DropdownList } from './DropdownList'
import { SelectOption } from './SelectOption'
import { AutoSizeInput } from './AutoSizeInput'
import {
  highlightIndex,
  highlightNext,
  highlightPrevious,
  NO_HIGHLIGHTED_INDEX,
  resetHighlightedIndex,
  updateItems,
  updateItemsWithoutReset,
} from './highlightableList'
import { isRedoEvent, isUndoEvent, useHistory } from './history'
import { OutlinedInput } from './OutlinedInput'
import { useStateOrProps } from './useStateOrProps'

const INITIAL_VALUES_STATE = {
  highlightedIndex: NO_HIGHLIGHTED_INDEX,
  items: [],
}

const INITIAL_VISIBLE_OPTIONS_STATE = {
  highlightedIndex: NO_HIGHLIGHTED_INDEX,
  items: [],
  additionalOptionComponent: null,
}

function getNativeInputValue({
  visibleOptions,
  isAdditionalOption,
  isOptionSelected,
  getOptionLabel,
  searchValue,
}) {
  if (visibleOptions.highlightedIndex !== NO_HIGHLIGHTED_INDEX) {
    const option = visibleOptions.items[visibleOptions.highlightedIndex]

    if (!isAdditionalOption(option) && !isOptionSelected(option)) {
      return getOptionLabel(option)
    }
  }

  return searchValue
}

export default function SelectMultiple({
  label,
  helper,
  value,
  onChange,
  options,
  getOptionLabel,
  getOptionValue,
  isOptionEnabled,
  getOptionTextToCopy: getOptionTextToCopyProp,
  getOptionFromCopiedText: getOptionFromCopiedTextProp,
  optionComponent,
  optionData,
  additionalOptionComponent,
  additionalOptionData,
  onSelectAdditionalOption,
  searchValue: searchValueProp,
  onSearchValueChange,
  noOptionSearch,
  optionSearchKeys,
  chipProps,
  size,
  error,
  disabled,
  adornment,
  icon,
  copySeparator,
  pasteSeparators,

  // Forwarded props.
  className,
  style,
  id,
  refProp,
  onBlur: onBlurProp,
  onCopy: onCopyProp,
  onFocus: onFocusProp,
  onKeyDown: onKeyDownProp,
  onKeyPress: onKeyPressProp,
  onPaste: onPasteProp,
  ...rest
}) {
  id = useID(id)

  /** @type {ValuesHistory} */
  const history = useHistory(value)

  const [focused, setFocused] = React.useState(false)

  // Allow us to keep the option list closed even when the props and state
  // would normally allow it, for example after an Escape key was hit by the
  // user.
  const [preventOpen, setPreventOpen] = React.useState(true)

  const [values, setValues] = React.useState(INITIAL_VALUES_STATE)

  const [visibleOptions, setVisibleOptions] = React.useState(
    INITIAL_VISIBLE_OPTIONS_STATE,
  )

  const [searchValue, setSearchValue] = useStateOrProps('', [
    searchValueProp,
    onSearchValueChange,
  ])

  const nativeInputElement = React.useRef(null)
  const arrowElement = React.useRef(null)
  const optionListElement = React.useRef(null)

  // We use a state to trigger a re-render to update `DropdownList`.
  const [wrapperElement, setWrapperElement] = React.useState(null)

  // We use a state to trigger the effect that make sure the highlighted index
  // is visible in the list.
  // When the option list just became open, the virtual list will be renders
  // "later": `VirtualList` will only render it if it has a size.
  // During that time the user might press an Arrow key and change the
  // highlighted index, so the effect must be re-run once the virtual list is
  // rendered.
  const [virtualListElement, setVirtualListElement] = React.useState(null)

  const inputRef = useComposedRefs([nativeInputElement, refProp])

  React.useEffect(() => {
    if (!focused) {
      setValues(resetHighlightedIndex())
      setPreventOpen(true)
    }
  }, [focused])

  React.useEffect(() => {
    if (values.highlightedIndex !== NO_HIGHLIGHTED_INDEX) {
      setPreventOpen(true)
    }
  }, [values.highlightedIndex])

  React.useEffect(() => {
    if (preventOpen) {
      setSearchValue('')
    } else {
      setValues(resetHighlightedIndex())
    }
  }, [preventOpen, setSearchValue])

  React.useEffect(() => {
    setValues(updateItemsWithoutReset(value))
  }, [value])

  React.useEffect(() => {
    if (searchValue !== '') {
      setValues(resetHighlightedIndex())
      setPreventOpen(false)
    }
  }, [searchValue])

  React.useEffect(() => {
    let visibleOptions = options

    if (!noOptionSearch && searchValue !== '') {
      visibleOptions = matchSorter(visibleOptions, searchValue, {
        keys:
          optionSearchKeys.length === 0 ? [getOptionLabel] : optionSearchKeys,
      })
    }

    if (additionalOptionComponent != null) {
      visibleOptions = [additionalOptionComponent, ...visibleOptions]
    }

    setVisibleOptions(
      updateItems(visibleOptions, { additionalOptionComponent }),
    )
  }, [
    options,
    searchValue,
    noOptionSearch,
    additionalOptionComponent,
    optionSearchKeys,
    getOptionLabel,
  ])

  const opened = focused && visibleOptions.items.length > 0 && !preventOpen

  React.useEffect(() => {
    if (!opened) {
      setVisibleOptions(resetHighlightedIndex())
    }
  }, [opened])

  React.useLayoutEffect(() => {
    if (opened && virtualListElement != null) {
      virtualListElement.scrollToItem(
        visibleOptions.highlightedIndex === NO_HIGHLIGHTED_INDEX
          ? 0
          : visibleOptions.highlightedIndex,
      )
    }
  }, [visibleOptions.highlightedIndex, virtualListElement, opened])

  function isOptionSelected(option) {
    const optionValue = getOptionValue(option)
    return values.items.some(value => getOptionValue(value) === optionValue)
  }

  function isAdditionalOption(option) {
    // We use `visibleOptions.additionalOptionComponent` instead of the prop
    // because this function can be called from event handlers.
    // They can be called after a render triggered by props changed, but before
    // the effects updating the visible options are ran.
    // We don't want to test the "currently visible" option against a "not yet
    // visible" additional option component.
    return (
      visibleOptions.additionalOptionComponent != null &&
      option === visibleOptions.additionalOptionComponent
    )
  }

  function getOptionOrAdditionalOptionValue(option) {
    return isAdditionalOption(option)
      ? 'select-additional-option'
      : getOptionValue(option)
  }

  function isOptionOrAdditionalOptionEnabled(option) {
    // The additional option is always enabled.
    return isAdditionalOption(option) || isOptionEnabled(option)
  }

  function isOptionOrAdditionalOptionSelected(option) {
    // The additional option is never selected.
    return !isAdditionalOption(option) && isOptionSelected(option)
  }

  function onChangeWithHistory(value) {
    onChange(value)
    history.push(value)
  }

  function onRemoveValue(index) {
    const newValues = values.items.slice()
    newValues.splice(index, 1)
    onChangeWithHistory(newValues)
  }

  function onRemoveValueAndResetHighlight(index) {
    setValues(highlightIndex(NO_HIGHLIGHTED_INDEX))
    onRemoveValue(index)
  }

  function onRemoveOptionAndResetHighlight(option) {
    const optionValue = getOptionValue(option)

    onRemoveValueAndResetHighlight(
      values.items.findIndex(
        otherOption => getOptionValue(otherOption) === optionValue,
      ),
    )
  }

  function onRemoveValueAndHighlightPrevious(index) {
    setValues(highlightIndex(index === 0 ? 0 : index - 1))
    onRemoveValue(index)
  }

  function onSelectOptionOrAdditionalOption(option) {
    if (isAdditionalOption(option)) {
      setSearchValue('')
      onSelectAdditionalOption()
    } else if (isOptionEnabled(option)) {
      if (isOptionSelected(option)) {
        if (searchValue === '') {
          onRemoveOptionAndResetHighlight(option)
        }
      } else {
        setSearchValue('')
        onChangeWithHistory(values.items.concat([option]))
      }
    }
  }

  function onWrapperClick() {
    if (!disabled) {
      setPreventOpen(preventOpen => !preventOpen)
    }
  }

  function onForwardFocus() {
    nativeInputElement.current.focus()
  }

  function onNativeInputFocus() {
    // We might have kept the focused state if an allowed element has been
    // focused.
    // See blur conditions in `onNativeInputBlur`.
    if (!focused) {
      setFocused(true)
    }
  }

  function onNativeInputBlur(event) {
    const focusedElement = event.relatedTarget

    // The wrapper, arrow adornment and option list forwards the focus to the
    // native input, but we still want to keep the focused state during that
    // period to avoid the side effects (e.g. closing the option list).
    if (
      focusedElement !== wrapperElement &&
      focusedElement !== arrowElement.current &&
      (optionListElement.current == null ||
        focusedElement !== optionListElement.current)
    ) {
      setFocused(false)
    }
  }

  function onEscape(event) {
    if (!preventOpen) {
      stopEvent(event)
      setPreventOpen(true)
    }
  }

  function onEnterOrTab(event) {
    if (visibleOptions.highlightedIndex !== NO_HIGHLIGHTED_INDEX) {
      stopEvent(event)
      onSelectOptionOrAdditionalOption(
        visibleOptions.items[visibleOptions.highlightedIndex],
      )
    }
  }

  function onBackspace(event) {
    if (searchValue === '') {
      stopEvent(event)

      if (visibleOptions.highlightedIndex === NO_HIGHLIGHTED_INDEX) {
        if (values.items.length > 0) {
          if (values.highlightedIndex === NO_HIGHLIGHTED_INDEX) {
            onRemoveValue(values.items.length - 1)
          } else {
            onRemoveValueAndHighlightPrevious(values.highlightedIndex)
          }
        }
      } else {
        const option = visibleOptions.items[visibleOptions.highlightedIndex]

        if (isOptionSelected(option)) {
          onRemoveOptionAndResetHighlight(option)
        } else {
          onEscape(event)
        }
      }
    }
  }

  function onHorizontalArrow(event, stateUpgrader) {
    if (searchValue === '' && values.items.length > 0) {
      stopEvent(event)
      setValues(stateUpgrader())
    }
  }

  function onVerticalArrow(event, stateUpgrader) {
    if (preventOpen) {
      stopEvent(event)
      setPreventOpen(false)
    } else if (opened) {
      stopEvent(event)
      setVisibleOptions(stateUpgrader())
    }
  }

  function onNativeInputKeyDown(event) {
    switch (event.key) {
      case 'Escape': {
        onEscape(event)
        break
      }

      case 'Enter':
      case 'Tab': {
        onEnterOrTab(event)
        break
      }

      case 'Backspace': {
        onBackspace(event)
        break
      }

      case 'ArrowLeft': {
        onHorizontalArrow(event, highlightPrevious)
        break
      }

      case 'ArrowRight': {
        onHorizontalArrow(event, highlightNext)
        break
      }

      case 'ArrowDown': {
        onVerticalArrow(event, highlightNext)
        break
      }

      case 'ArrowUp': {
        onVerticalArrow(event, highlightPrevious)
        break
      }

      default: {
        break
      }
    }
  }

  function onNativeInputKeyPress(event) {
    // When the user presses a key, we want to open the option list and
    // initialize the search with the value of the key pressed.
    // After that, the native input will handle the input changes.
    if (searchValue === '' && event.key !== 'Enter') {
      stopEvent(event)
      setSearchValue(event.key)
    }
  }

  function onWrapperHistoryChange(event) {
    // The event handler is added on the wrapper so we need to check the select
    // is not disabled.
    if (!disabled && searchValue === '') {
      if (isUndoEvent(event)) {
        stopEvent(event)
        setPreventOpen(true)
        onChange(history.previous())
      } else if (isRedoEvent(event)) {
        stopEvent(event)
        setPreventOpen(true)
        onChange(history.next())
      }
    }
  }

  function onNativeInputCopy(event) {
    const selection = document.getSelection().toString()

    if (selection === '' && searchValue === '' && values.items.length > 0) {
      stopEvent(event)

      function getOptionTextToCopy(option) {
        return getOptionTextToCopyProp == null
          ? getOptionLabel(option)
          : getOptionTextToCopyProp(option)
      }

      event.clipboardData.setData(
        'text/plain',
        values.items.map(getOptionTextToCopy).join(copySeparator),
      )
    }
  }

  function onNativeInputPaste(event) {
    if (!disabled && searchValue === '') {
      const text = event.clipboardData.getData('text/plain')

      if (text !== '') {
        stopEvent(event)

        function getOptionFromCopiedText(text) {
          return getOptionFromCopiedTextProp == null
            ? options.find(option => getOptionLabel(option) === text)
            : getOptionFromCopiedTextProp(text)
        }

        const values = text
          .split(new RegExp(`(?:${pasteSeparators.join('|')})`))
          .filter(value => value !== '')
          .map(getOptionFromCopiedText)
          .filter(option => option != null)

        onChangeWithHistory(uniqBy(values, getOptionValue))
      }
    }
  }

  const adornments = [
    ...ensureArray(adornment),
    icon == null ? null : <Adornment icon={icon} />,
    <Adornment
      disabled={disabled}
      onFocus={onForwardFocus}
      onClick={onWrapperClick}
      icon={opened ? 'sort-asc' : 'sort-desc'}
      ref={arrowElement}
    />,
  ].filter(Boolean)

  const nativeInputValue = getNativeInputValue({
    visibleOptions,
    isAdditionalOption,
    isOptionSelected,
    getOptionLabel,
    searchValue,
  })

  const chipsClassName = classNames(
    'SelectMultiple__chips',
    `SelectMultiple__chips--${size}`,
  )

  const searchChipClassName = classNames(
    'SelectMultiple__searchChip',
    `SelectMultiple__searchChip--${size}`,
    {
      'SelectMultiple__searchChip--hidden':
        !focused ||
        (searchValue === '' &&
          (visibleOptions.highlightedIndex === NO_HIGHLIGHTED_INDEX ||
            isAdditionalOption(
              visibleOptions.items[visibleOptions.highlightedIndex],
            ) ||
            isOptionSelected(
              visibleOptions.items[visibleOptions.highlightedIndex],
            ))),
    },
  )

  function renderValues() {
    const highlightedOptionValue =
      visibleOptions.highlightedIndex === NO_HIGHLIGHTED_INDEX
        ? null
        : getOptionValue(visibleOptions.items[visibleOptions.highlightedIndex])

    return values.items.map((value, index) => {
      let handlers = {}

      if (!disabled) {
        handlers = {
          onRemove: () => onRemoveValueAndResetHighlight(index),
          onClick: event => {
            // We dont want the click event to bubble up to the wrapper because
            // it toggles the `preventOpen` state.
            stopEvent(event)
            setValues(highlightIndex(index))
          },
        }
      }

      const props = callPropAccessor(chipProps, value)
      const optionValue = getOptionValue(value)
      const optionLabel = getOptionLabel(value)

      const active =
        values.highlightedIndex === index ||
        optionValue === highlightedOptionValue ||
        searchValue === optionLabel

      return (
        <Chip
          color="purple"
          {...props}
          key={optionValue}
          label={optionLabel}
          size={size}
          active={active}
          {...handlers}
          className={classNames(
            'SelectMultiple__chip',
            `SelectMultiple__chip--${size}`,
            props.className,
          )}
        />
      )
    })
  }

  return (
    <>
      <OutlinedInput
        label={label}
        helper={helper}
        showHelperOnHover
        size={size}
        contentType="action"
        multiline
        error={error}
        filled={values.items.length > 0 || searchValue !== ''}
        focused={focused}
        disabled={disabled}
        adornments={adornments}
        id={id}
        tabIndex={-1}
        onFocus={onForwardFocus}
        onClick={onWrapperClick}
        // We handle the History on the wrapper and not the native input as it
        // can be readonly and wont triggers the needed events.
        onKeyDown={onWrapperHistoryChange}
        className={className}
        style={style}
        ref={setWrapperElement}
      >
        <span className={chipsClassName}>
          {renderValues()}

          <Chip color="light-grey" size={size} className={searchChipClassName}>
            <AutoSizeInput
              autoComplete="off"
              spellCheck="off"
              autoCorrect="off"
              autoCapitalize="off"
              {...rest}
              value={nativeInputValue}
              onChange={e => setSearchValue(e.target.value)}
              disabled={disabled}
              // It's easier to use a readonly input to simulate a label and to
              // use this same input for the search, than switching between a
              // span and an input and having to manage the focus properly.
              readOnly={searchValue === ''}
              id={id}
              onFocus={composeHandlers([onNativeInputFocus, onFocusProp])}
              onBlur={composeHandlers([onNativeInputBlur, onBlurProp])}
              onKeyDown={composeHandlers([onNativeInputKeyDown, onKeyDownProp])}
              onKeyPress={composeHandlers([
                onNativeInputKeyPress,
                onKeyPressProp,
              ])}
              onCopy={composeHandlers([onNativeInputCopy, onCopyProp])}
              onPaste={composeHandlers([onNativeInputPaste, onPasteProp])}
              ref={inputRef}
            />
          </Chip>
        </span>
      </OutlinedInput>

      {opened && (
        <DropdownList
          referenceElement={wrapperElement}
          items={visibleOptions.items}
          // `getItemLabel` is never called for the additional option.
          getItemLabel={getOptionLabel}
          getItemValue={getOptionOrAdditionalOptionValue}
          isItemEnabled={isOptionOrAdditionalOptionEnabled}
          isItemSelected={isOptionOrAdditionalOptionSelected}
          onSelectItem={onSelectOptionOrAdditionalOption}
          highlightedIndex={visibleOptions.highlightedIndex}
          itemComponent={SelectOption}
          itemData={{
            additionalOptionComponent: visibleOptions.additionalOptionComponent,
            additionalOptionData,
            optionComponent,
            optionData,
            getOptionLabel,
          }}
          onFocus={onForwardFocus}
          ref={optionListElement}
          listProps={{ ref: setVirtualListElement }}
        />
      )}
    </>
  )
}

SelectMultiple.propTypes = {
  /**
   * The label of the select.
   */
  label: PropTypes.string.isRequired,

  /**
   * The helper message of the select.
   */
  helper: PropTypes.string,

  /**
   * The current value of the select.
   */
  value: PropTypes.array.isRequired,

  /**
   * Invoked with the new value when it changed.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * The list of options.
   */
  options: PropTypes.array.isRequired,

  /**
   * Invoked to get a displayable label for an option.
   */
  getOptionLabel: PropTypes.func,

  /**
   * Invoked to get a unique identifier for an option.
   */
  getOptionValue: PropTypes.func,

  /**
   * Invoked to know whether an option is enabled.
   * All options are enabled by default.
   */
  isOptionEnabled: PropTypes.func,

  /**
   * Invoked to get the text to copy for an option.
   * By default the text will be the result of `getOptionLabel`.
   */
  getOptionTextToCopy: PropTypes.func,

  /**
   * Invoked to get an option that match a to copied text.
   * By default the options will be tested against the results of
   * `getOptionLabel`.
   */
  getOptionFromCopiedText: PropTypes.func,

  /**
   * The component to use to render an option.
   */
  optionComponent: PropTypes.elementType,

  /**
   * Contextual data to be passed to the `optionComponent` as a `data` prop.
   * This is a light-weight alternative to React's built-in context API.
   */
  optionData: PropTypes.any,

  /**
   * The component to use to render the additional option.
   */
  additionalOptionComponent: PropTypes.elementType,

  /**
   * Contextual data to be passed to the `additionalOptionComponent` as a
   * `data` prop.
   * This is a light-weight alternative to React's built-in context API.
   */
  additionalOptionData: PropTypes.any,

  /**
   * Invoked when the additional options is selected.
   */
  onSelectAdditionalOption: PropTypes.func,

  /**
   * The value typed by the user to search the options.
   * This prop should be used in conjunction with `onSearchValueChange`.
   */
  searchValue: PropTypes.string,

  /**
   * Invoked with the new search value.
   * This prop should be used in conjunction with `searchValue`.
   */
  onSearchValueChange: PropTypes.func,

  /**
   * Whether the select should perform the search in the options or not.
   */
  noOptionSearch: PropTypes.bool,

  /**
   * The keys to use for searching the options.
   * If provided, this list will override the the default one which is
   * `getOptionLabel`.
   * See match-sorter's `key` option.
   */
  optionSearchKeys: PropTypes.array,

  /**
   * Props to apply on each `Chip`.
   * If `chipProps` is a function, it will be called with its associated value
   * as parameter.
   */
  chipProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),

  /**
   * The size of the select.
   * Must be one of `medium` or `small`.
   */
  size: PropTypes.oneOf(OutlinedInput.SIZES),

  /**
   * The error message to display.
   * If set, the error message takes precedence over `helper`.
   */
  error: PropTypes.string,

  /**
   * Whether the select is disabled or not.
   * Adornments can still be activated when the select is disabled.
   */
  disabled: PropTypes.bool,

  /**
   * The adornments of the select.
   */
  adornment: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),

  /**
   * Name of the icon to show as adornment.
   * If provided, the icon will be displayed on the right side of the
   * adornments.
   * This is a shorthand for `adornment={<Adornment icon={icon} />}`.
   */
  icon: PropTypes.string,

  /**
   * The separator to use between values when the user copies them.
   */
  copySeparator: PropTypes.string,

  /**
   * The separators to split the values on when the user pastes some text.
   */
  pasteSeparators: PropTypes.arrayOf(PropTypes.string),
}

SelectMultiple.defaultProps = {
  getOptionLabel: o => `${o}`,
  getOptionValue: o => `${o}`,
  isOptionEnabled: () => true,
  noOptionSearch: false,
  optionSearchKeys: [],
  chipProps: {},
  size: 'medium',
  disabled: false,
  copySeparator: '\t',
  pasteSeparators: ['\t'],
}

const SelectMultipleRef = React.forwardRef((props, ref) => (
  <SelectMultiple {...props} refProp={ref} />
))

SelectMultipleRef.defaultProps = SelectMultiple.defaultProps
SelectMultipleRef.SIZES = OutlinedInput.SIZES

export { SelectMultipleRef as SelectMultiple }
