export function downloadExcel(response, name) {
  const options = { filename: `${name}`, type: 'xlsx' }
  response.then(data => {
    let blob
    const byteString = atob(data)
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    blob = new Blob([ia], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const url = window.URL.createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.href = url
    tempLink.setAttribute('download', `${options.filename}.${options.type}`)
    tempLink.click()
  })
}
