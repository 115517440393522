import { useAuthenticatedFetch } from '../hooks/useAuthenticatedFetch'

export function useGetStations() {
  const { response, ...rest } = useAuthenticatedFetch(`/users/me`)

  return { stations: response?.stations || [], ...rest }
}

export function useGetStationWaves(stationId) {
  const { response, ...rest } = useAuthenticatedFetch(
    `/station/${stationId}/waves`,
  )

  return { waves: response || [], ...rest }
}

export function useSearchStationTitles(stationId, queryParams) {
  const { response, ...rest } = useAuthenticatedFetch(
    `/station/${stationId}/titles`,
    {},
    queryParams,
  )

  return { titles: response, ...rest }
}
