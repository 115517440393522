import { navigate } from '@reach/router'
import { stopEvent, useInput } from '@ttrmz/react-utils'
import { useTranslation } from 'react-i18next'
import { Card, Input, Modal } from '..'
import { SELECTIONS_TYPES } from '../../config/constants'
import { useWave } from '../../contexts/wave'
import { showSnackbar } from '../../core/showSnackbar'
import { useCreateSelecton } from '../../server/selectionsControllers'
import { Button } from '../Button/Button'

export function SelectionsActionsModal({ opened, onClose, titles }) {
  const { value: name, onChange, setValue: setName } = useInput('')
  const { createSelection } = useCreateSelecton()
  const { refetchSelections } = useWave()
  const { t } = useTranslation()

  const handleCreateSelection = event => {
    stopEvent(event)

    createSelection({
      public: false,
      name,
      type: SELECTIONS_TYPES.selection,
      data: {
        isFavourites: false,
        titles,
      },
    }).then(() => {
      refetchSelections()
      onClose()
      setName('')

      showSnackbar(`${name} ${t('successCreate')}`, {})
    })
  }

  return (
    <Modal open={opened} onClose={onClose}>
      <Card component="form">
        <Modal.Title className="SelectionsActionsModal__title">
          {t('playlistName')}
        </Modal.Title>

        <Input
          icon="edit"
          className="Compare__AddModal__Input"
          label={t('playlistName')}
          onChange={onChange}
          value={name}
        />

        <div className="SelectionsActionsModal__action">
          <Button color="grey" onClick={onClose}>
            {t('cancel')}
          </Button>

          <Button type="submit" onClick={handleCreateSelection}>
            {t('confirm')}
          </Button>
        </div>
      </Card>
    </Modal>
  )
}
