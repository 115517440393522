import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

function ToggleComponent({ className, style, innerRef, ...rest }) {
  return (
    <span className={classNames('Toggle', className)} style={style}>
      <input
        {...rest}
        ref={innerRef}
        type="checkbox"
        className="Toggle__input"
      />

      <span className="Toggle__span" />
    </span>
  )
}

export const Toggle = React.forwardRef((props, ref) => (
  <ToggleComponent {...props} innerRef={ref} />
))

Toggle.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
}
