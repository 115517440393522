import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

// WARNING
// Do not change this map without changing the one in the z-indexes SASS file.
const Z_INDEXES_MAPPING = {
  base: 0,
  container: 100,
  'container-popovers': 200,
  'main-popovers': 300,
  topbar: 400,
  sidebar: 500,
  'app-popovers': 600,
}

const Z_INDEXES = Object.keys(Z_INDEXES_MAPPING)

export default function SelectMultipleCard({
  elevation,
  zIndex,
  zIndexOffset,

  // Forwarded props.
  className,
  style,
  refProp,
  ...rest
}) {
  const cardClassName = classNames(className, 'SelectMultipleCard')

  const zIndexStyle =
    zIndex == null ? {} : { zIndex: Z_INDEXES_MAPPING[zIndex] + zIndexOffset }

  return (
    <div
      {...rest}
      className={cardClassName}
      style={{ ...style, ...zIndexStyle }}
      ref={refProp}
    />
  )
}

SelectMultipleCard.propTypes = {
  /**
   * A preset of z-index to use.
   */
  zIndex: PropTypes.oneOf(Z_INDEXES),

  /**
   * The offset to apply on the `zIndex` preset.
   */
  zIndexOffset: PropTypes.number,
}

SelectMultipleCard.defaultProps = {
  elevation: 3,
  zIndexOffset: 0,
}

const SelectMultipleCardRef = React.forwardRef((props, ref) => (
  <SelectMultipleCard {...props} refProp={ref} />
))

SelectMultipleCardRef.defaultProps = SelectMultipleCard.defaultProps
SelectMultipleCardRef.Z_INDEXES = Z_INDEXES
SelectMultipleCardRef.Z_INDEXES_MAPPING = Z_INDEXES_MAPPING

export { SelectMultipleCardRef as SelectMultipleCard }
