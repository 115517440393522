import { useWave } from '../contexts/wave'

export function useTags() {
  const { wave } = useWave()

  return wave.tags.map(tag => ({
    id: tag.key, // format for accessors mapping in rich table
    ...tag,
  }))
}
