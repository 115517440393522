import { Table } from '../Table'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Tooltify } from '../Tooltify/Tooltify'

export function GroupsTabRow({
  className,
  activeGroup,
  onChange,
  groups,
  ...props
}) {
  return (
    <Table.Row className={classNames('GroupsTabRow', className)} {...props}>
      {groups.map(group => (
        <Table.Cell
          key={group.id}
          weight={1}
          onClick={() => activeGroup !== group.id && onChange(group)}
          className={classNames('GroupsTabRow__cell', {
            'GroupsTabRow__cell--active': activeGroup === group.id,
          })}
        >
          {' '}
          <Tooltify children={group.name} />
        </Table.Cell>
      ))}
    </Table.Row>
  )
}

GroupsTabRow.propTypes = {
  groups: PropTypes.array.isRequired,
  activeGroup: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
