import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Icon } from '../Icon'

export const Dropdown = React.forwardRef(({ className, ...rest }, ref) => (
  <div className={classNames('Dropdown', className)} ref={ref} {...rest} />
))

const DropdownItem = React.forwardRef(
  (
    { children, className, active, disabled, highlighted, as, ...rest },
    ref,
  ) => {
    const Component = as || 'button'

    return (
      <Component
        ref={ref}
        disabled={disabled}
        className={classNames('DropdownItem', className, {
          'DropdownItem--active': active,
          'DropdownItem--highlighted': highlighted,
          'DropdownItem--disabled': disabled,
        })}
        {...rest}
      >
        <span className="DropdownItem__label">{children}</span>

        {active && <Icon className="DropdownItem__checkbox" name="checkbox" />}
      </Component>
    )
  },
)

DropdownItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
  as: PropTypes.elementType,
}

Dropdown.Item = DropdownItem
