/**
 * The state object for managing highlightable lists.
 * A highlightable list is simply a list of items that allow one item to be
 * highlighted.
 * This state should be used in conjunction with `React.useState`.
 *
 * The exported functions return `React.useState` upgrader function to simplify
 * the API from:
 *
 * ```js
 * setState(s => upgrader(s, additionalParameter))
 * ```
 *
 * to:
 *
 * ```js
 * setState(upgrader(additionalParameter))
 * ```
 *
 * @template T
 *
 * @typedef {object} HighlightableListState
 *
 * @property {number} highlightedIndex The index of the highlighted item in
 * `items`, or `NO_HIGHLIGHTED_INDEX` if there is none.
 *
 * @property {T[]} items The current list of items.
 */

/**
 * A callback to pass to the state modifier function from `React.useState`.
 *
 * @template T
 * @callback HighlightableListStateUpgrader
 * @param {HighlightableListState<T>} state
 * @returns {HighlightableListState<T>}
 */

/**
 * Small constant to avoid having `-1` all around the code.
 */
export const NO_HIGHLIGHTED_INDEX = -1

/**
 * Update the list of items and reset the highlighted index to
 * `NO_HIGHLIGHTED_INDEX`.
 *
 * @template T
 * @param {T[]} items
 * @param {object} [additionalState={}]
 * @returns {HighlightableListStateUpgrader<T>}
 */
export function updateItems(items, additionalState = {}) {
  return state => {
    return {
      ...state,
      ...additionalState,
      items,
      highlightedIndex: NO_HIGHLIGHTED_INDEX,
    }
  }
}

/**
 * Update the list of items and try to keep the highlighted index in range of
 * the new list.
 *
 * @template T
 * @param {T[]} items
 * @returns {HighlightableListStateUpgrader<T>}
 */
export function updateItemsWithoutReset(items) {
  return ({ highlightedIndex, ...state }) => {
    let newHighlightedIndex = NO_HIGHLIGHTED_INDEX

    if (items.length > 0 && highlightedIndex !== NO_HIGHLIGHTED_INDEX) {
      newHighlightedIndex = Math.min(highlightedIndex, items.length - 1)
    }

    return { ...state, items, highlightedIndex: newHighlightedIndex }
  }
}

/**
 * Highlight the next item of the list.
 *
 * @template T
 * @returns {HighlightableListStateUpgrader<T>}
 */
export function highlightNext() {
  return ({ highlightedIndex, items, ...state }) => {
    switch (highlightedIndex) {
      case NO_HIGHLIGHTED_INDEX: {
        return { ...state, items, highlightedIndex: 0 }
      }

      case items.length - 1: {
        return { ...state, items, highlightedIndex: NO_HIGHLIGHTED_INDEX }
      }

      default: {
        return { ...state, items, highlightedIndex: highlightedIndex + 1 }
      }
    }
  }
}

/**
 * Highlight the previous item of the list.
 *
 * @template T
 * @returns {HighlightableListStateUpgrader<T>}
 */
export function highlightPrevious() {
  return ({ highlightedIndex, items, ...state }) => {
    switch (highlightedIndex) {
      case NO_HIGHLIGHTED_INDEX: {
        return { ...state, items, highlightedIndex: items.length - 1 }
      }

      case 0: {
        return { ...state, items, highlightedIndex: NO_HIGHLIGHTED_INDEX }
      }

      default: {
        return { ...state, items, highlightedIndex: highlightedIndex - 1 }
      }
    }
  }
}

/**
 * Highlight the item at the specified index.
 *
 * @template T
 * @param {number} index
 * @returns {HighlightableListStateUpgrader<T>}
 */
export function highlightIndex(index) {
  return state => {
    return { ...state, highlightedIndex: index }
  }
}

/**
 * Set the highlighted item back to `NO_HIGHLIGHTED_INDEX`.
 *
 * @template T
 * @returns {HighlightableListStateUpgrader<T>}
 */
export function resetHighlightedIndex() {
  return highlightIndex(NO_HIGHLIGHTED_INDEX)
}
