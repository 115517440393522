import { Icon } from '../Icon'
export function BreadcrumbItem({ withSeparator, content }) {
  return (
    <li className="BreadcrumbItem">
      {withSeparator && (
        <span className="BreadcrumbItem__separator">
          <Icon name="chevron-right" />
        </span>
      )}
      <span className="BreadcrumbItem__content">{content}</span>
    </li>
  )
}
