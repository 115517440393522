import { useWave } from '../contexts/wave'
import { useAuthenticatedFetch } from '../hooks/useAuthenticatedFetch'
import { useAuthenticatedLazyFetch } from '../hooks/useAuthenticatedLazyFetch'

export function useGetSelections(waveId) {
  const { response, ...rest } = useAuthenticatedFetch(
    `/wave/${waveId}/selections`,
  )
  return { selections: response, ...rest }
}

export function useEditSelecton(selectionId) {
  const { wave } = useWave()
  const { lazyFetch, ...rest } = useAuthenticatedLazyFetch(
    `/wave/${wave.id}/selection/${selectionId}`,
  )

  const editSelection = body =>
    lazyFetch({
      method: 'POST',
      body: JSON.stringify(body),
    })

  return { editSelection, ...rest }
}

export function useCreateSelecton() {
  const { wave } = useWave()
  const { lazyFetch, ...rest } = useAuthenticatedLazyFetch(
    `/wave/${wave.id}/selection`,
  )

  const createSelection = body =>
    lazyFetch({
      method: 'POST',
      body: JSON.stringify(body),
    })

  return { createSelection, ...rest }
}

export function useDeleteSelection(selectionId) {
  const { wave } = useWave()
  const { lazyFetch, ...rest } = useAuthenticatedLazyFetch(
    `/wave/${wave.id}/selection/${selectionId}`,
  )

  const deleteSelection = () =>
    lazyFetch({
      method: 'DELETE',
    })

  return { deleteSelection, ...rest }
}
