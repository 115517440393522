import { useLockBodyScroll, useOnClickOutside } from '@ttrmz/react-utils'
import React from 'react'
import ReactDOM from 'react-dom'
import classnames from 'classnames'

const ModalContent = React.forwardRef(
  ({ children, onKeyPressDevice, className }, ref) => {
    useLockBodyScroll()

    return (
      <>
        <div className="Modal__overlay" />
        <div
          ref={ref}
          className="Modal__wrapper"
          tabIndex={0}
          onKeyDown={onKeyPressDevice}
        >
          <div className={classnames('Modal', className)}>{children}</div>
        </div>
      </>
    )
  },
)

export function Modal({ open, children, onClose, className }) {
  const modal = React.useRef()
  const moutingPoint = React.useMemo(() => document.createElement('div'), [])
  useOnClickOutside(modal, () => onClose())
  React.useLayoutEffect(() => {
    if (open) {
      document.body.appendChild(moutingPoint)
      return () => document.body.removeChild(moutingPoint)
    }
  }, [open, moutingPoint])

  if (!open) {
    return null
  }
  const onKeyPressDevice = event => {
    event.keyCode === 13 && onClose()
  }
  return ReactDOM.createPortal(
    <ModalContent
      className={className}
      children={children}
      onKeyPressDevice={onKeyPressDevice}
      ref={modal}
    />,
    moutingPoint,
  )
}

function ModalTitle({ children, className }) {
  const classes = classnames('ModalTitle', className)
  return <h3 className={classes}>{children}</h3>
}

Modal.Title = ModalTitle
