import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { useTableContext } from '../useTableContext'

function TableRow({ hover, disabled, refProp, className, selected, ...rest }) {
  const { section } = useTableContext()

  return (
    <div
      {...rest}
      ref={refProp}
      className={classNames(className, 'TableRow', {
        'TableRow--hover': hover ?? section === 'body',
        'TableRow--disabled': disabled,
        'TableRow--selected': selected,
        [`TableRow--section-${section}`]: section != null,
      })}
    />
  )
}

TableRow.propTypes = {
  hover: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
}

TableRow.defaultProps = {
  disabled: false,
}

const TableRowWithRef = React.forwardRef((props, ref) => (
  <TableRow {...props} refProp={ref} />
))

export { TableRowWithRef as TableRow }
