import { useOnClickOutside } from '@ttrmz/react-utils'
import classNames from 'classnames'
import React from 'react'
import {
  Dropdown,
  Icon,
  Breadcrumb,
  SearchTitles,
  Toggle,
} from '../../components'
import { WaveSwitcher } from '../../components/WaveSwitcher/WaveSwitcher'
import { useTranslation } from 'react-i18next'
import { useKeycloak } from '../../contexts/keycloak'
import { useLayout } from '../../contexts/layout'

export function Topbar() {
  const { t } = useTranslation()
  const [userDropdownOpened, toggleUserDropdown] = React.useState(false)
  const [isSearchFocused, onSearchFocused] = React.useState(false)
  const dropdownUser = React.useRef()

  const { user, compact, logout, toggleCompactMode } = useKeycloak()
  const { hasSidebar, breadcrumbPath } = useLayout()

  const iconName = userDropdownOpened ? 'sort-asc' : 'sort-desc'

  useOnClickOutside(dropdownUser, () => toggleUserDropdown(false))

  return (
    <div className={classNames('Topbar', { 'Topbar--sidebar': hasSidebar })}>
      {!hasSidebar && <div className="Topbar__logo" />}
      {hasSidebar && <Breadcrumb path={breadcrumbPath} />}
      {!isSearchFocused && hasSidebar && <WaveSwitcher />}
      <div className="Topbar--right">
        {hasSidebar && (
          <SearchTitles
            isSearchFocused={isSearchFocused}
            onSearchFocused={onSearchFocused}
          />
        )}
        <div
          className="Topbar__user"
          onClick={() => toggleUserDropdown(true)}
          ref={dropdownUser}
        >
          {user?.name}

          <Icon name={iconName} className="Topbar__icon" />

          {userDropdownOpened && (
            <Dropdown className="Topbar__Dropdown">
              <Dropdown.Item as="label">
                {t('compactMode')}
                <Toggle
                  checked={compact}
                  className="Topbar__toggle"
                  onChange={toggleCompactMode}
                />
              </Dropdown.Item>

              <Dropdown.Item onClick={logout}>{t('logout')}</Dropdown.Item>
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  )
}
