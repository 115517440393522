import React from 'react'
import { useTranslation } from 'react-i18next'

export function SummaryPanel({ data, count, segments }) {
  const { t } = useTranslation()
  if (!data) return <div />
  return (
    <div className="SummaryPanel">
      <div className="SummaryPanel__category__value--title">
        <div className="SummaryPanel__category__value__title--bold">
          {t('all')}
        </div>
        <div className="SummaryPanel__category__value SummaryPanel__category__value--right">
          <span className="SummaryPanel__category__value--percentage">{`100 %`}</span>
          <span className="SummaryPanel__category__value--count">
            {Math.round(count)}
          </span>
        </div>
      </div>
      {Object.keys(data).map(category => (
        <div className="SummaryPanel__category" key={category}>
          <div className="SummaryPanel__category__title">{category}</div>
          <div className="SummaryPanel__category__values">
            {segments
              .filter(
                segment =>
                  segment.ref !== 'ensemble' &&
                  data[category].values.find(val => segment.ref === val.ref),
              )
              .map(segment => {
                const value = data[category].values.find(
                  val => segment.ref === val.ref,
                )
                return (
                  <div
                    className="SummaryPanel__category__value"
                    key={`${category}-${value.name}`}
                  >
                    <div className="SummaryPanel__category__value__title">
                      {value.name}
                    </div>
                    <div className="SummaryPanel__category__value SummaryPanel__category__value--right">
                      <span className="SummaryPanel__category__value--percentage">{`${value.percent?.toFixed(
                        0,
                      )} %`}</span>
                      <span className="SummaryPanel__category__value--count">{`${value.count?.toFixed(
                        0,
                      )}`}</span>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      ))}
    </div>
  )
}
