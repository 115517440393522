import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Icon } from '../../Icon'
import { ListItemText } from './ListItemText'
// import './_ListItem.scss'
// This value is defined in JS and not CSS so it can be used in windowed list.
const ITEM_HEIGHT = 48

export default function ListItem({
  selected,
  highlighted,
  highlightOnHover,
  disabled,
  component: Component,

  // Forwarded props.
  children,
  className,
  style,
  refProp,
  ...rest
}) {
  const itemClassName = classNames('ListItem', className, {
    'ListItem--highlightOnHover': highlightOnHover && !disabled,
    'ListItem--selected': selected,
    'ListItem--highlighted': highlighted,
    'ListItem--disabled': disabled,
  })

  const componentProps =
    typeof Component === 'string' ? {} : { selected, highlighted, disabled }

  return (
    <Component
      {...rest}
      {...componentProps}
      className={itemClassName}
      style={{ height: ITEM_HEIGHT, ...style }}
      ref={refProp}
    >
      {children}

      {selected && <Icon className="DropdownItem__checkbox" name="checkbox" />}
    </Component>
  )
}

ListItem.propTypes = {
  /**
   * Whether to highlight the item on hover or not.
   */
  highlightOnHover: PropTypes.bool,

  /**
   * Whether the item is selected or not.
   */
  selected: PropTypes.bool,

  /**
   * Whether the item is highlighted or not.
   */
  highlighted: PropTypes.bool,

  /**
   * Whether the item is disabled or not.
   */
  disabled: PropTypes.bool,

  /**
   * The component to render.
   * If the supplied component isn't a native HTML tag name, the props
   * `selected`, `highlighted` and `disabled` will be passed down to it.
   */
  component: PropTypes.elementType,
}

ListItem.defaultProps = {
  highlightOnHover: false,
  selected: false,
  highlighted: false,
  disabled: false,
  component: 'div',
}

const ListItemRef = React.forwardRef((props, ref) => (
  <ListItem {...props} refProp={ref} />
))

ListItemRef.defaultProps = ListItem.defaultProps
ListItemRef.HEIGHT = ITEM_HEIGHT
ListItemRef.Text = ListItemText

export { ListItemRef as ListItem }
