import Keycloak from 'keycloak-js'
import { keycloakConfig } from '../config/keycloak'

const REFRESHED_TOKEN_MS = 60000

export const keycloak = Keycloak(keycloakConfig)

export const setRefreshToken = () => {
  const validTokenTime = Math.round(
    keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000,
  )
  setTimeout(async () => {
    try {
      const refreshed = await keycloak.updateToken(70)
      console.log('refreshed', refreshed)
      if (refreshed) console.debug('Token refreshed' + refreshed)
      else
        console.warn(`Token not refreshed, valid for ${validTokenTime} seconds`)
    } catch (error) {
      console.error('Failed to refresh token', error)
    }
  }, REFRESHED_TOKEN_MS)
}
