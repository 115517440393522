import React from 'react'
import { TABLE_CONTEXT } from './tableContext'

export function useTableContext() {
  const context = React.useContext(TABLE_CONTEXT)

  if (context == null) {
    throw new Error('useTableContext cannot be used outside of a Table.')
  }

  return context
}
