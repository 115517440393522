import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const COLORS = ['green', 'red', 'pink']

export const Numeric = React.forwardRef(
  ({ children, className, color, ...props }, ref) => {
    return (
      <span
        ref={ref}
        className={classNames('Numeric', className, {
          [`Numeric--${color}`]: color,
        })}
        {...props}
      >
        {children == null
          ? '-'
          : isNaN(children)
          ? children
          : Math.round(children)}
      </span>
    )
  },
)

Numeric.propTypes = {
  children: PropTypes.number,
  color: PropTypes.oneOf(COLORS),
}
