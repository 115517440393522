import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { Icon } from '../Icon'

export class Input extends Component {
  static propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    helper: PropTypes.string,
    icon: PropTypes.string,
    type: PropTypes.string,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onReset: PropTypes.func,
    isLoading: PropTypes.bool,
  }

  static defaultProps = {
    type: 'text',
    id: Math.random()
      .toString(36)
      .substr(2, 9),
  }
  constructor(props) {
    super(props)
    this.state = {
      isFocused: false,
      isFilled: !!props.value,
      isManagingFocus: false,
    }
  }

  static getDerivedStateFromProps(nextProps) {
    return { isFilled: !!nextProps.value }
  }

  onChange = event => {
    this.setState({ isFilled: !!event.target.value })
    this.props.onChange(event)
  }

  onReset = () => {
    this.setState({ isFilled: false, isFocused: false })
    this.props.onReset()
  }

  onBlur = () => {
    const { isFilled } = this.state
    this.nameInput.blur()
    const { onBlur } = this.props
    this.setState({ isFocused: false })
    this.setState({
      DefaultLabel: isFilled ? false : true,
    })
    return onBlur && onBlur()
  }

  onFocus = () => {
    const { onFocus } = this.props
    this.nameInput.focus()
    this.setState({ isFocused: true, DefaultLabel: true })
    return onFocus && onFocus()
  }

  render() {
    const {
      icon,
      helper,
      value,
      label,
      id,
      onChange,
      disabled,
      error,
      onBlur,
      onFocus,
      className,
      onReset,
      isLoading,
      style,
      ...props
    } = this.props

    const { isFocused, isFilled } = this.state

    const inputClass = classNames('input__container', className, {
      'input__container--default': !disabled && !error,
      'input__container--default-filled': !error && !disabled && isFilled,
      'input__container--default-focused': !disabled && isFocused,
      'input__container--error': error && !disabled,
      'input__container--disabled': disabled,
      loading: isLoading,
    })

    const helperClass = classNames({
      'helper--display': !disabled && !error && isFocused,
      'helper--default': !disabled && !error,
      'helper--error': error && !disabled,
      'helper--disabled': disabled,
    })
    return (
      <div className={inputClass} style={style}>
        {!isLoading && (
          <Fragment>
            <span className="label">{label}</span>
            <input
              id={id}
              ref={input => {
                this.nameInput = input
              }}
              value={value}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              disabled={disabled}
              {...props}
            />

            <span className={helperClass}>{error || helper}</span>

            {icon && <Icon name={icon} className="input-icon" />}
          </Fragment>
        )}
      </div>
    )
  }
}
