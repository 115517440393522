import PropTypes from 'prop-types'
import React from 'react'
import { ListItem } from '../ListItem'

export function DropdownListItem({
  index,
  style,
  data: {
    items,
    getItemLabel,
    isItemEnabled,
    isItemSelected,
    itemComponent: ItemComponent,
    itemData,
    onSelectItem,
    highlightedIndex,
  },
}) {
  const item = items[index]

  const commonProps = {
    style,
    highlighted: highlightedIndex === index,
  }

  if (isItemEnabled != null) {
    commonProps.disabled = !isItemEnabled(item)
  }

  if (isItemSelected != null) {
    commonProps.selected = isItemSelected(item)
  }

  if (!commonProps.disabled) {
    commonProps.onClick = event => {
      event.stopPropagation()
      onSelectItem(item)
    }
  }

  if (ItemComponent == null) {
    return (
      <ListItem highlightOnHover {...commonProps}>
        <ListItem.Text>{getItemLabel(item)}</ListItem.Text>
      </ListItem>
    )
  }

  return <ItemComponent {...commonProps} item={item} data={itemData} />
}

DropdownListItem.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  data: PropTypes.shape({
    items: PropTypes.array.isRequired,
    getItemLabel: PropTypes.func.isRequired,
    isItemEnabled: PropTypes.func,
    isItemSelected: PropTypes.func,
    itemComponent: PropTypes.elementType,
    itemData: PropTypes.any,
    onSelectItem: PropTypes.func.isRequired,
    highlightedIndex: PropTypes.number.isRequired,
  }).isRequired,
}
