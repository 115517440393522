import { navigate } from '@reach/router'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useWave } from '../../contexts/wave'
import { showSnackbar } from '../../core/showSnackbar'
import { useEditSelecton } from '../../server/selectionsControllers'
import { Dropdown } from '../Dropdown'

export function SelectionsActionsSelection({ selection, titles, onActionEnd }) {
  const { refetchSelections, wave } = useWave()
  const { editSelection } = useEditSelecton(selection.id)
  const { t } = useTranslation()

  const prevTitles = selection.data?.titles || []
  const name = selection.data.isFavourites ? t('fav') : selection.name

  const handleActionClick = () => {
    editSelection({
      ...selection,
      data: {
        ...selection.data,
        titles: [
          ...prevTitles,
          ...titles.filter(id => !prevTitles.includes(id)),
        ],
      },
    }).then(() => {
      onActionEnd()
      refetchSelections()

      showSnackbar(`${t('addedTo')} ${name}`, {
        onAction: () => navigate(`/wave/${wave.id}/selections/${selection.id}`),
        actionLabel: t('see'),
      })
    })
  }

  return (
    <Dropdown.Item onClick={handleActionClick} key={selection.id}>
      {name}
    </Dropdown.Item>
  )
}
