import { useTranslation } from 'react-i18next'
import {
  Progress,
  Rank,
  Score,
  TablePlayer,
  Title,
  Variation,
} from '../components'
import { NUMERIC_CELL_WIDTH, INVERTED_REFS } from './constants'

export const useDataTypes = score => {
  const { t } = useTranslation()
  const DATA_TYPES = {
    rank: {
      label: '#',
      accessor: title => <Rank>{title.rank}</Rank>,
      align: 'center',
      width: 40,
      sortField: 'rank',
    },
    artist: {
      label: t('artist'),
      accessor: title => null,
      weight: 0,
      align: 'left',
      sortField: 'artist',
    },
    title: {
      label: t('title'),
      accessor: title => (
        <>
          <TablePlayer titleRef={title.ref} />
          <Title title={title} />
        </>
      ),
      align: 'left',
      weight: 2,
      sortField: 'name',
    },
    progress: {
      label: '',
      accessor: title => {
        return <Progress value={title.percent || title.score} />
      },
      align: 'left',
      weight: 3,
    },
    variation: {
      label: t('variation'),
      accessor: title => (
        <Variation
          value={title.variation}
          isInverted={INVERTED_REFS.includes(score.ref)}
        />
      ),
      align: 'right',
      width: 120,
      sortField: `values.minus1.diffs.${score.ref}`,
      defaultSortOrder: 'desc',
    },
    score: {
      label: `${score.name}`,
      accessor: title => {
        return <Score>{title.score}</Score>
      },
      align: 'right',
      width: NUMERIC_CELL_WIDTH,
      sortField: `values.current.values.${score.ref}`,
      defaultSortOrder: INVERTED_REFS.includes(score.ref) ? 'desc' : 'asc',
    },
  }
  return { DATA_TYPES }
}

export const DATA_TYPES = {
  rank: {
    label: '#',
    accessor: title => <Rank>{title.rank}</Rank>,
    align: 'center',
    width: 40,
    sortField: 'rank',
  },
  artist: {
    label: 'artist', // il nous faut les trads ici :/
    accessor: title => null,
    align: 'left',
    weight: 0,
    sortField: 'artist',
  },
  title: {
    label: 'title', // il nous faut les trads ici :/
    accessor: title => (
      <>
        <TablePlayer titleRef={title.ref} />
        <Title title={title} />
      </>
    ),
    align: 'left',
    weight: 3,
    sortField: 'name',
  },
  progress: {
    label: '',
    accessor: title => {
      return <Progress value={title.percent || title.score} />
    },
    align: 'left',
    weight: 3,
  },
  variation: {
    label: 'variation', // il nous faut les trads ici :/
    accessor: title => <Variation value={title.variation} />,
    align: 'right',
    width: NUMERIC_CELL_WIDTH,
    sortField: 'variation',
    defaultSortOrder: 'desc',
  },
  score: {
    label: 'Score (%)',
    accessor: title => {
      return <Score>{title.score}</Score>
    },
    align: 'right',
    width: NUMERIC_CELL_WIDTH,
    sortField: 'score',
    defaultSortOrder: 'desc',
  },
}
