import { toast } from 'react-toastify'
import { SnackbarContent } from '../components'

export function showSnackbar(
  message,
  { actionLabel, onAction, ...options } = {},
) {
  return toast(
    <SnackbarContent
      message={message}
      actionLabel={actionLabel}
      onAction={onAction}
    />,
    options,
  )
}
