/**
 * @param {any} value
 * @returns {any[]}
 */
export function ensureArray(value) {
  if (value == null) {
    return []
  }

  return Array.isArray(value) ? value : [value]
}
