import React from 'react'
import classNames from 'classnames'

export function Checkbox(props) {
  const { name, id, children, checked } = props
  const handleChange = e => {
    props.onChange(e)
  }
  return (
    <div className="CheckboxInput" onClick={handleChange}>
      <input type="checkbox" id={id} name={name} checked={checked} readOnly />
      <div
        className={classNames(`checkbox__square`, {
          'checkbox__square--checked': checked,
          'checkbox__square--unchecked': !checked,
        })}
      />
      <label className="checkbox__label" htmlFor={name}>
        {children}
      </label>
    </div>
  )
}
