import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { warn } from '../warnings'
// import { Icon } from '../Icon'
// import './_Adornment.scss'

export default function Adornment({
  icon,
  text,
  onClick,

  // Forwarded props.
  className,
  refProp,
  ...rest
}) {
  React.useEffect(() => {
    if (icon != null && text != null) {
      warn('<Adornment /> Only one of `icon` and `text` should be used.')
    } else if (icon == null && text == null) {
      warn('<Adornment /> At least one of `icon` or `text` should be used.')
    }
  }, [icon, text])

  const button = onClick != null
  const Component = button ? 'button' : 'span'

  const adornmentClassName = classNames('Adornment', className, {
    'Adornment--button': button,
  })

  return (
    <Component
      {...rest}
      onClick={onClick}
      tabIndex={button ? -1 : undefined}
      ref={refProp}
      className={adornmentClassName}
    >
      {icon && <i className={`icon icon-${icon}`} />}
      {text}
    </Component>
  )
}

Adornment.propTypes = {
  /**
   * The icon name to display.
   * Either `icon` or `text` must be provided.
   */
  icon: PropTypes.string,

  /**
   * The text content to display.
   * Either `icon` or `text` must be provided.
   */
  text: PropTypes.string,

  /**
   * If provided, the component will render a button element.
   */
  onClick: PropTypes.func,
}

const AdornmentRef = React.forwardRef((props, ref) => (
  <Adornment {...props} refProp={ref} />
))

export { AdornmentRef as Adornment }
