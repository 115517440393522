import React from 'react'
import { Icon } from '..'
import { useTracks } from '../../contexts/tracks'
import { useWave } from '../../contexts/wave'

export function RoundedPlayer({ titleRef }) {
  const { wave } = useWave()
  const [play, setPlay] = React.useState(false)
  const [currentTime, setCurrentTime] = React.useState(0)
  const [duration, setDurarion] = React.useState(0)

  const { currentTrack, setCurrentTrack } = useTracks()

  // Debug override
  const assetUrl =
    process.env.NODE_ENV === 'development'
      ? 'https://staging.hitindex.hyperworld.fr/hooks/'
      : `${process.env.REACT_APP_API_DOMAIN}hooks/`
  const audioUrl = `${assetUrl}${wave.station.name}_hit_${titleRef}.mp3`

  const ID = React.useMemo(
    () =>
      '_' +
      Math.random()
        .toString(36)
        .substr(2, 9),
    [],
  )
  const onPlay = event => {
    let currentTimeInterval = null
    event.stopPropagation()
    const audio = document.getElementById(ID)
    setPlay(!play)
    if (!play) {
      if (currentTrack) {
        currentTrack.pause()
        currentTrack.currentTime = 0
      }
      currentTimeInterval = setInterval(() => {
        setCurrentTime(audio.currentTime)
      }, 100)
      setCurrentTrack(audio)
      setDurarion(audio.duration)
      return audio.play()
    }
    audio.pause()
    audio.currentTime = 0
    setCurrentTime(0)
    clearInterval(currentTimeInterval)
  }

  React.useEffect(() => {
    if (currentTime === 0) setPlay(false)
  }, [currentTime])

  const onEnd = () => {
    setPlay(false)
  }
  const progress = play ? (currentTime / duration) * 100 : 0

  const dashArray = 440
  const dashOffset = 440 - (440 * progress) / 100

  return (
    <div className="RoundedPlayer" onClick={onPlay}>
      <Icon name={play ? 'pause' : 'play'} className="RoundedPlayer__icon" />
      <div className="progress">
        <svg width="160" height="160" xmlns="http://www.w3.org/2000/svg">
          <g>
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#da1379" />
                <stop offset="100%" stopColor="#6d3891" />
              </linearGradient>
            </defs>
            <circle
              id="circle"
              className="circle_animation"
              style={{
                strokeDasharray: dashArray,
                strokeDashoffset: dashOffset,
              }}
              r="69.85699"
              cy="81"
              cx="81"
              strokeWidth="16"
              fill="none"
              stroke="url(#gradient)"
            />
          </g>
        </svg>
      </div>

      <audio
        id={ID}
        onEnded={onEnd}
        src={audioUrl}
        style={{ display: 'none' }}
      />
    </div>
  )
}
