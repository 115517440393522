import queryString from 'query-string'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { downloadCSV } from '../core/downloadCSV'
import { downloadExcel } from '../core/downloadExcel'
import { showSnackbar } from '../core/showSnackbar'

export function useFetch(url, options, queryParams = {}) {
  const { t } = useTranslation()
  const [response, setResponse] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [called, setCalled] = React.useState(0)
  const params = React.useMemo(() => queryString.stringify(queryParams), [
    queryParams,
  ])

  const fetchData = async () => {
    setLoading(true)

    try {
      const res = await fetch(`${url}?${params}`, options)

      const json = await res.json()

      setResponse(json)
      setLoading(false)
      setCalled(prev => prev + 1)
    } catch (error) {
      setError(error)
    }
  }

  const exportData = async ({
    filename,
    url: overrideUrl,
    exportParam,
    activeView,
    type,
    method,
    body,
  }) => {
    const activeParams = activeView
      ? { export: exportParam, ...queryParams }
      : { export: exportParam }
    const params = queryString.stringify(activeParams)
    showSnackbar(t('exportConfirm'))
    try {
      const res = await fetch(`${overrideUrl || url}?${params}`, {
        headers: {
          Authorization: options.headers.Authorization,
          'Content-Type': 'application/json',
        },
        method: method || options.method || 'GET',
        body,
      })
      const cleanFilename = filename.replace('/', '_')
      if (type === 'csv') return downloadCSV(res.text(), cleanFilename)
      return downloadExcel(res.text(), cleanFilename)
    } catch (error) {
      setError(error)
    }
  }

  React.useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, options.body])

  return {
    response,
    error,
    loading,
    called,
    refetch: fetchData,
    exportData,
    setLoading,
  }
}
