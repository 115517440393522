import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { TABLE_CONTEXT } from '../tableContext'
import { useWatchPosition } from '@ttrmz/react-utils'

export function TableHead({
  sticky,
  children,
  className,
  style = {},
  stickyStyle = {},
  ...rest
}) {
  const headRef = React.useRef()

  const value = React.useMemo(() => ({ section: 'head' }), [])
  const { y } = useWatchPosition(headRef)

  const isSticky = sticky && y <= 0

  return (
    <TABLE_CONTEXT.Provider value={value}>
      <div
        style={{ ...style, ...(isSticky ? stickyStyle : {}) }}
        ref={headRef}
        className={classNames(className, 'TableHead', {
          'TableHead--sticky': isSticky,
        })}
        {...rest}
      >
        {children}
      </div>
    </TABLE_CONTEXT.Provider>
  )
}

TableHead.propTypes = {
  sticky: PropTypes.bool,
  children: PropTypes.node,
}

TableHead.defaultProps = {
  sticky: false,
}
