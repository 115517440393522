import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Icon } from '../Icon'
import React from 'react'

export const Button = React.forwardRef(
  ({ className, icon, children, disabled, color, type, ...rest }, ref) => (
    <button
      ref={ref}
      disabled={disabled}
      className={classNames('Button', `Button--${color}`, className, {
        'Button--outlined': type === 'outlined',
        'Button--disabled': disabled,
      })}
      {...rest}
    >
      {icon && <Icon className="Button__icon" name={icon} />}
      {children}
    </button>
  ),
)

Button.propTypes = {
  icon: PropTypes.oneOf(Icon.NAMES),
  disabled: PropTypes.bool,
}
