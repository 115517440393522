import { useOnClickOutside } from '@ttrmz/react-utils'
import React from 'react'
import { useFavourites } from '../../contexts/favourites'
import { useWave } from '../../contexts/wave'
import { Button } from '../Button'
import { Dropdown } from '../Dropdown'
import { SelectionsActionsModal } from './SelectionsActionsModal'
import { SelectionsActionsSelection } from './SelectionsActionsSelection'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

export function SelectionsActions({
  titles,
  disabled,
  children,
  onActionEnd,
  className,
  ...rest
}) {
  const { t } = useTranslation()
  const [menuOpen, setMenuOpen] = React.useState(false)
  const [modalOpen, setModalOpen] = React.useState(false)
  const actionRef = React.useRef()
  const { selections: waveSelections } = useWave()
  const { favourites } = useFavourites()

  useOnClickOutside(actionRef, () => setMenuOpen(false))

  const selections = [
    favourites,
    ...waveSelections.filter(selection => !selection.data.isFavourites),
  ]

  const handleCloseModal = () => {
    setModalOpen(false)
    setMenuOpen(false)
    onActionEnd()
  }

  return !!titles.length ? (
    <div
      ref={actionRef}
      className={classNames('SelectionsActions', className)}
      {...rest}
    >
      <Button
        color="grey"
        onClick={() => setMenuOpen(true)}
        disabled={disabled}
        className=""
        icon="selection-add"
      >
        {t('addToPlaylist')}
      </Button>

      {menuOpen && (
        <Dropdown className="SelectionsActions__actions">
          <Dropdown.Item onClick={() => setModalOpen(true)}>
            {t('newPlaylist')}
          </Dropdown.Item>

          {selections.map(selection => (
            <SelectionsActionsSelection
              selection={selection}
              titles={titles}
              key={selection.id}
              onActionEnd={() => {
                setMenuOpen(false)
                onActionEnd()
              }}
            />
          ))}
        </Dropdown>
      )}

      <SelectionsActionsModal
        titles={titles}
        onClose={handleCloseModal}
        opened={modalOpen}
      />
    </div>
  ) : null
}
