import classNames from 'classnames'

export function TitleType({ className, type, display, ...props }) {
  return (
    type !== 'recurrent' && (
      <span
        className={classNames(`TitleType TitleType--${type}`, className, {
          'TitleType--column': display === 'column',
        })}
        {...props}
      >
        {type}
      </span>
    )
  )
}
