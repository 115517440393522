import { throttle } from 'lodash'
import React from 'react'

const DEFAULT_HISTORY_CAPACITY = 50
const DEFAULT_PUSH_THROTTLE_TIMEOUT = 500

export function useHistory(initialValue, options) {
  // The parameters are only used for initialisation, all updates are ignored.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useMemo(() => createHistory(initialValue, options), [])
}

function createHistory(
  initialValue,
  {
    capacity = DEFAULT_HISTORY_CAPACITY,
    pushThrottle = DEFAULT_PUSH_THROTTLE_TIMEOUT,
  } = {},
) {
  let values = [initialValue]
  let index = 0

  const push = throttle(value => {
    // The previously known futur is now lost.
    // We don't keep track of parallel universes.
    // GREAT SCOTT!
    if (index < values.length - 1) {
      values = values.slice(0, index + 1)
    }

    values = values.concat([value])
    index = index + 1

    if (values.length > capacity) {
      values = values.slice(1)
      index = index - 1
    }
  }, pushThrottle)

  function previous() {
    // Make sure any previous `push` are ran before.
    push.flush()

    if (index > 0) {
      index = index - 1
    }

    return values[index]
  }

  function next() {
    // Make sure any previous `push` are ran before.
    push.flush()

    if (index < values.length - 1) {
      index = index + 1
    }

    return values[index]
  }

  function getState() {
    return { index, values }
  }

  return { push, previous, next, getState }
}

const MAC =
  typeof navigator !== `undefined`
    ? navigator.appVersion.indexOf('Mac') !== -1
    : true
const SHORTCUT_KEY = MAC ? 'metaKey' : 'ctrlKey'

export function isUndoEvent(event) {
  // cmd-z / ctrl-z
  return (
    event[SHORTCUT_KEY] && event.key.toLowerCase() === 'z' && !event.shiftKey
  )
}

export function isRedoEvent(event) {
  const key = event.key.toLowerCase()

  return (
    // cmd-shift-z / ctrl-shift-z
    (event[SHORTCUT_KEY] && event.shiftKey && key === 'z') ||
    // cmd-y / ctrl-y
    (event[SHORTCUT_KEY] && key === 'y' && !event.shiftKey)
  )
}
