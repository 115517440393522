import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { ALIGNMENTS } from '../tablePropTypes'
import { useTableContext } from '../useTableContext'

function TableCell({
  align,
  weight,
  width,
  component: Component,
  refProp,
  className,
  style,
  ...rest
}) {
  const { section } = useTableContext()
  const widthStyles = !!width ? { width, maxWidth: width, minWidth: width } : {}

  return (
    <Component
      {...rest}
      ref={refProp}
      style={{ ...style, ...widthStyles, flex: weight }}
      className={classNames(
        className,
        'TableCell',
        `TableCell--align-${align}`,
        {
          [`TableCell--section-${section}`]: section,
          'TableCell--with-weight': !width && weight > 0,
        },
      )}
    />
  )
}

TableCell.propTypes = {
  align: PropTypes.oneOf(ALIGNMENTS),
  weight: PropTypes.number,
  width: PropTypes.number,
  component: PropTypes.elementType,
}

TableCell.defaultProps = {
  align: 'center',
  component: 'span',
  weight: 1,
}

const TableCellWithRef = React.forwardRef((props, ref) => (
  <TableCell {...props} refProp={ref} />
))

export { TableCellWithRef as TableCell }
