import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { composeRefs } from 'react-behave'
import ReactDOM from 'react-dom'
import { usePopper } from '../usePopper'
import { VirtualList } from '../VirtualList'
import { ListItem } from '../ListItem'
import { SelectMultipleCard } from '../SelectMultipleCard'
import { DropdownListItem } from './DropdownListItem'
// import './_DropdownList.scss'
const MAX_VISIBLE_SUGGESTIONS = 5

function DropdownList({
  referenceElement,
  items,
  getItemLabel,
  getItemValue,
  isItemEnabled,
  isItemSelected,
  onSelectItem,
  highlightedIndex,
  elevation,
  itemComponent,
  itemData,
  listProps,

  // Forwarded props.
  className,
  style,
  refProp,
  ...rest
}) {
  const mountingPoint = React.useMemo(() => document.createElement('div'), [])

  // We use a state to trigger a re-render to update `usePopper`.
  const [listElement, setListElement] = React.useState(null)

  React.useLayoutEffect(() => {
    if (listElement != null) {
      const referenceRect = referenceElement.getBoundingClientRect()
      listElement.style.minWidth = `${referenceRect.width}px`
    }
  })

  const popper = usePopper({ popper: listElement, reference: referenceElement })

  React.useLayoutEffect(() => {
    document.body.appendChild(mountingPoint)
    return () => document.body.removeChild(mountingPoint)
  }, [mountingPoint])

  const { scheduleUpdate } = popper

  // The height of the item list (popover) depends on the number of items.
  // If it is displayed above the reference, we want to make sure it is still
  // correctly positioned.
  React.useLayoutEffect(() => {
    scheduleUpdate()
  }, [items.length, highlightedIndex, scheduleUpdate])

  const suggestionListHeight =
    Math.min(MAX_VISIBLE_SUGGESTIONS, items.length) * ListItem.HEIGHT

  return ReactDOM.createPortal(
    <SelectMultipleCard
      {...rest}
      elevation={elevation}
      data-placement={popper.placement}
      tabIndex={-1}
      style={{ height: suggestionListHeight, ...popper.style, ...style }}
      className={classNames('DropdownList', className)}
      ref={composeRefs([setListElement, refProp])}
    >
      <VirtualList
        listProps={{
          ...listProps,
          children: DropdownListItem,
          itemCount: items.length,
          itemSize: ListItem.HEIGHT,
          itemKey: index => getItemValue(items[index]),
          itemData: {
            items,
            getItemLabel,
            isItemEnabled,
            isItemSelected,
            itemComponent,
            itemData,
            onSelectItem,
            highlightedIndex,
          },
        }}
        className="DropdownList__virtualList"
      />
    </SelectMultipleCard>,
    mountingPoint,
  )
}

DropdownList.propTypes = {
  referenceElement: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  getItemLabel: PropTypes.func.isRequired,
  getItemValue: PropTypes.func.isRequired,
  isItemEnabled: PropTypes.func,
  isItemSelected: PropTypes.func,
  onSelectItem: PropTypes.func.isRequired,
  highlightedIndex: PropTypes.number,
  elevation: PropTypes.number,
  itemComponent: PropTypes.elementType,
  itemData: PropTypes.any,
  listProps: PropTypes.object,
}

DropdownList.defaultProps = {
  highlightedIndex: -1,
  elevation: 3,
}

const DropdownListRef = React.forwardRef((props, ref) => (
  <DropdownList {...props} refProp={ref} />
))

DropdownListRef.defaultProps = DropdownList.defaultProps

export { DropdownListRef as DropdownList }
