import classNames from 'classnames'
import React from 'react'
import { TableBody } from './TableBody'
import { TableCell } from './TableCell'
import { TableCellSortableText } from './TableCellSortableText'
import { TableCellText } from './TableCellText'
import { TABLE_CONTEXT } from './tableContext'
import { TableHead } from './TableHead'
import { ALIGNMENTS, SECTIONS, SORT_DIRECTIONS } from './tablePropTypes'
import { TableRow } from './TableRow'
import { TableRowTitle } from './TableRowTitle'

export function Table({ compact, className, ...rest }) {
  const value = React.useMemo(
    () => ({ compact, section: 'none', sticky: false }),
    [compact],
  )

  return (
    <TABLE_CONTEXT.Provider value={value}>
      <div
        {...rest}
        className={classNames(className, 'Table', {
          'Table--compact': compact,
        })}
      />
    </TABLE_CONTEXT.Provider>
  )
}

Table.defaultProps = {
  density: 'medium',
}

Table.Head = TableHead
Table.Body = TableBody
Table.Row = TableRow
Table.Cell = TableCell
Table.TextContent = TableCellText
Table.SortableTextContent = TableCellSortableText
Table.TableRowTitle = TableRowTitle

Table.ALIGNMENTS = ALIGNMENTS
Table.SECTIONS = SECTIONS
Table.SORT_DIRECTIONS = SORT_DIRECTIONS
