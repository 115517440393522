import React from 'react'
import { useSelection } from '../hooks/useSelection'
import { useWave } from './wave'

const FavouritesContext = React.createContext({})

export function useFavourites() {
  return React.useContext(FavouritesContext)
}

export function FavouritesContextProvider({ value, ...rest }) {
  const { selections } = useWave()

  const [favourites] = selections.filter(
    selection => selection?.data?.isFavourites,
  )

  const {
    titles,
    toggleTitle: toggleFavourite,
    removeTitles: removeFavourites,
    addTitles: addFavourites,
  } = useSelection(favourites)

  const values = React.useMemo(
    () => ({
      favourites,
      titles,
      toggleFavourite,
      removeFavourites,
      addFavourites,
    }),
    [favourites, titles, toggleFavourite, removeFavourites, addFavourites],
  )

  return <FavouritesContext.Provider value={values} {...rest} />
}
