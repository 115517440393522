import PropTypes from 'prop-types'
import { composeHandlers } from 'react-behave'
import { SnackbarMessage } from './SnackbarMessage'

export function SnackbarContent({
  message,
  onAction,
  actionLabel,

  // Passed down by `toast`.
  // https://github.com/fkhadra/react-toastify#render-a-component
  closeToast,
}) {
  message =
    typeof message === 'string' ? (
      <SnackbarMessage children={message} />
    ) : (
      message
    )

  return (
    <>
      {message}

      {onAction != null && (
        <button
          children={actionLabel}
          onClick={composeHandlers([onAction, closeToast])}
          className="SnackbarContent__action"
        />
      )}
    </>
  )
}

SnackbarContent.propTypes = {
  message: PropTypes.node.isRequired,
  onAction: PropTypes.func,
  actionLabel: PropTypes.string,
}

SnackbarContent.defaultProps = {
  actionLabel: 'Close',
}
